.product-image .product-badges { 
	@media (min-width: 768px) {	
		top: 8px;
		right: 8px;
		bottom: auto;
		left: auto;
		align-items: end;
		flex-direction: column;
	}
}
.product-badges {
	position: absolute;
	bottom: 4px;
	left: 4px;
	@media (min-width: 768px) {
		left: 8px;
		bottom: 8px;
		
	}

	display: flex;
	/* flex-direction: column; */
	flex-wrap: wrap;
	gap: 4px;
	@media (min-width: 768px) {
		gap: 8px;
	}

	& > * {
		padding: 4px 6px;
		font-size: 12px;
		@media (min-width: 768px) {
			padding: 6px 8px;
			font-size: 14px;
		}
		/* border-radius: 8px 0 8px 0; */
		/* font-weight: bold; */
		/* border-width: 1px;
		border-style: solid; */
		/* border-radius: 0 4px 4px 0; */
		/* border-radius: 4px; */
		font-weight: bold;
		/* box-shadow: 2px 2px 2px rgba(0, 0, 0, .2); */

		line-height: 1;
		width: max-content;
	}
	.sale {
		/* background: #ffef98;
		color: #ff0048; */
		/* background: linear-gradient(90deg, #3baa35 0%, #48c841 100%);
		color: white; */
		//background: linear-gradient(90deg, #b12704 0, #e62e00 100%);
		/* background: #b12704;
		color: #fff; */

		color: #fff;
		background: #B12704;
		/* border-color: #b12704;
		border-right-color: #e8c0b6; */
	}
	.new {
		/* background: linear-gradient(90deg, #3baa35 0%, #48c841 100%); */
		background: $color-brand;
		color: white;
		/* background: #caebff;
		color: #066197; */
	}

	.genere {
		background: #eee;
		color: #000;
	}
}