.me-lff-notification { 
	text-align: center;
	margin: 0 8px 18px 8px;
	font-family: Helvetica, Arial, sans-serif;
}
.checkout-types .me-lff-notification {
	margin-top: 18px;
}
.cart-totals.sticky .me-lff-notification {
	margin: 16px 0;
}


.me-lff-notification p {
	/* margin: 0 8px; */
	font-size: 12px ;
	line-height: 14px ;
}
.me-lff-notification p .price {
	display: inline-block;
	font-weight: bold;
	/* color: #3bb77e; */
}
.me-lff-notification-text {
	text-align: center;
 }
.me-lff-notification-text svg {
	width: 16px;
	height: 16px;
	aspect-ratio: 1/1;
	margin-right: 2px;
	margin-bottom: -2px;
	line-height: 14px;
	aspect-ratio: 1/1;
	/* fill: #515154; */
}
/* minicart override svg rule */
.page-header-container .me-lff-notification-text svg {
	display: inline-block;
	width: 16px;
	height: 16px;
}
.page-header-container .me-lff-notification {
	margin: 0;
}
.me-lff-notification #me-lff-progress-bar {
	width: 100%;
	background-color: #eee;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05);
	margin-top: 8px;
	height: 14px;
}
.me-lff-notification #me-lff-bar {
	content: none;
	height: 14px;
	width: 0%;
	min-width: 14px;
	background: linear-gradient(45deg, #008c6c, #3bb77e);
	text-align: center;
	line-height: 14px;
	color: white;
}
.me-lff-notification #me-lff-progress-bar,
.me-lff-notification #me-lff-bar {
	border-radius: 6px;
}
.me-lff-current,
.me-lff-freeshipping {
	margin: 4px 8px;	
	font-size: 12px;
	line-height: initial;
}
.me-lff-current .price,
.me-lff-freeshipping .price {
	font-size: 12px;
	line-height: 12px;
}
.me-lff-current small,
.me-lff-freeshipping small {
	font-size: 10px;
}
.me-lff-freeshipping .price {
	font-weight: bold;
}
.me-lff-freeshipping-available {
	font-size: 14px !important;
	line-height: 22px !important;
}
.me-lff-freeshipping-available strong {
	color: #03916f;
}
.me-lff-freeshipping-available svg {
	width: 20px !important;
	height: 20px !important;
}
.me-lff-freeshipping-available b {
	font-family: "Montserrat", sans-serif;
	font-size: 16px;
	display: inline !important;
	font-weight: bold !important;
}
/* ======================================================================================= */
.checkout-cart-index .main .me-lff-notification {
	
}