/*COMPONENTS -> MISC*/
.separator {
    border: none;
    content: '';
    height: 1px;

    width: 80%;
    margin: 20px auto;
    background: #ececec;

  /*   height: 1px;
    width: 50%;
    margin: 20px auto;
    background: transparent;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,.04);
    border-bottom: 1px solid #f4f4f4; */
}

.change-column-count.selected {
  font-weight: bold;
  color: $color-brand;
}

/*! purgecss start ignore */
.category-description {
  position: relative;
}
.read-more {
  cursor: pointer;
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  background-image: linear-gradient(180deg, transparent, #fff);
}
.read-more button {
  @extend .btn-ui;
  
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 4px 16px;
}
/*! purgecss end ignore */