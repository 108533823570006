/*! purgecss start ignore */
body:not(.cms-index-index) {
	.footer-before-container {
		margin-top: 80px;
	}	
}
.footer-before-container {
	padding: 40px 16px;

	background: #eee;
	color: $color-body;

	& > .footer {
		/* padding: 0 24px; */
		@media (min-width: 1024px) {
			padding: 0;
			width: 100%;
		}

		display: flex;
		justify-content: center;
		flex-direction: column;
		
		//max-width: var(--max-width-container);
		margin: 0 auto;
	
		h2 {
			margin-bottom: 8px;
		}

		& > ul {

			margin-top: 16px;

			display: flex;
			flex-direction: column;

			@media (min-width: 1024px) {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
			}

			gap: 20px 0;

			& > li {

							
				display: flex;
				flex-direction: column;
				gap: 6px;
				
				& > a,
				& > strong {
					font-size: 14px;
					font-weight: bold; 
					color: $color-text;
				}

				& > ul {
					font-size: 12px;
					font-weight: normal;
					color: $color-body;				
					display: flex;
					flex-direction: column;
					gap: 4px;
				}

				font-size: 12px;
				font-weight: normal;
				color: $color-body;
			}
		}
	}
}

.footer-container {

	display: flex;
	justify-content:center;

	padding: 40px 16px;

	//background-color: $color-body;
	background-color: #000;
	color: white;


	.footer {
		/*padding: 0 24px;*/
		@media (min-width: 1024px) {
			padding: 0;
			width: 100%;
		}

		display: flex;
		flex-direction: column;
		flex: 1;
		max-width: var(--max-width-container);
		margin: 0 auto;
		
		gap: 20px 0;
		
		a,
		label {
			color: white;
		}

		.payment-list {
			margin: 0 auto;
		}

		.footer_columns {
			display: flex;
			flex-direction: column;
			gap: 40px;
			color: white;

			@media (min-width: 1024px) {
				flex-direction: row;
			}
			justify-content: space-between;
			& > * {
				flex: 1;
			}
			a,
			p,
			label {
				color: white;
			}
			.links > ul {
				display: flex;
				flex-direction: column;	
				&.social {
					flex-direction: row;
				}
				gap: 4px;
				& > li {
					& > a {
						display: flex;
						gap: 8px;
						align-items: center;
						svg {
							fill: #fff;
							width: 32px;
							height: 32px;
						}
					}
				}
			}
		}
	}

	.copyright {
		padding-top: 20px;
		border-top: 1px solid $color-grey-2;
		text-align: center;
		font-size: 12px;
		line-height: normal;
	}
}

.block-subscribe {

	.block-content {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 8px;
		margin: 8px 0;
	}
}

.icon-phone {
	display: flex;
	align-items: center;
	gap: 4px;
	color: #fff;
	&::before {
		content: url('data:image/svg+xml,\
		<svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">\
			<path d="M20.97 17.33C20.97 17.69 20.89 18.06 20.72 18.42C20.55 18.78 20.33 19.12 20.04 19.44C19.55 19.98 19.01 20.37 18.4 20.62C17.8 20.87 17.15 21 16.45 21C15.43 21 14.34 20.76 13.19 20.27C12.04 19.78 10.89 19.12 9.75 18.29C8.58811 17.4401 7.49169 16.5041 6.47 15.49C5.45877 14.472 4.5261 13.3789 3.68 12.22C2.86 11.08 2.2 9.94 1.72 8.81C1.24 7.67 1 6.58 1 5.54C1 4.86 1.12 4.21 1.36 3.61C1.6 3 1.98 2.44 2.51 1.94C3.15 1.31 3.85 1 4.59 1C4.87 1 5.15 1.06 5.4 1.18C5.66 1.3 5.89 1.48 6.07 1.74L8.39 5.01C8.57 5.26 8.7 5.49 8.79 5.71C8.88 5.92 8.93 6.13 8.93 6.32C8.93 6.56 8.86 6.8 8.72 7.03C8.59 7.26 8.4 7.5 8.16 7.74L7.4 8.53C7.29 8.64 7.24 8.77 7.24 8.93C7.24 9.01 7.25 9.08 7.27 9.16C7.3 9.24 7.33 9.3 7.35 9.36C7.53 9.69 7.84 10.12 8.28 10.64C8.73 11.16 9.21 11.69 9.73 12.22C10.27 12.75 10.79 13.24 11.32 13.69C11.84 14.13 12.27 14.43 12.61 14.61C12.66 14.63 12.72 14.66 12.79 14.69C12.87 14.72 12.95 14.73 13.04 14.73C13.21 14.73 13.34 14.67 13.45 14.56L14.21 13.81C14.46 13.56 14.7 13.37 14.93 13.25C15.16 13.11 15.39 13.04 15.64 13.04C15.83 13.04 16.03 13.08 16.25 13.17C16.47 13.26 16.7 13.39 16.95 13.56L20.26 15.91C20.52 16.09 20.7 16.3 20.81 16.55C20.91 16.8 20.97 17.05 20.97 17.33Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"></path>\
		</svg>');
		width: 18px;
		height: 18px;
	}
}
.icon-mail {
	display: flex;
	align-items: center;
	gap: 4px;
	color: #fff;
	&::before {
		content: url('data:image/svg+xml,\
		<svg  width="18" height="18" fill="none" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">\
			<g style=" stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">\
				<path d="M 75.546 78.738 H 14.455 C 6.484 78.738 0 72.254 0 64.283 V 25.716 c 0 -7.97 6.485 -14.455 14.455 -14.455 h 61.091 c 7.97 0 14.454 6.485 14.454 14.455 v 38.567 C 90 72.254 83.516 78.738 75.546 78.738 z M 14.455 15.488 c -5.64 0 -10.228 4.588 -10.228 10.228 v 38.567 c 0 5.64 4.588 10.229 10.228 10.229 h 61.091 c 5.64 0 10.228 -4.589 10.228 -10.229 V 25.716 c 0 -5.64 -4.588 -10.228 -10.228 -10.228 H 14.455 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"></path>\
				<path d="M 11.044 25.917 C 21.848 36.445 32.652 46.972 43.456 57.5 c 2.014 1.962 5.105 -1.122 3.088 -3.088 C 35.74 43.885 24.936 33.357 14.132 22.83 C 12.118 20.867 9.027 23.952 11.044 25.917 L 11.044 25.917 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"></path>\
				<path d="M 46.544 57.5 c 10.804 -10.527 21.608 -21.055 32.412 -31.582 c 2.016 -1.965 -1.073 -5.051 -3.088 -3.088 C 65.064 33.357 54.26 43.885 43.456 54.412 C 41.44 56.377 44.529 59.463 46.544 57.5 L 46.544 57.5 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"></path>\
				<path d="M 78.837 64.952 c -7.189 -6.818 -14.379 -13.635 -21.568 -20.453 c -2.039 -1.933 -5.132 1.149 -3.088 3.088 c 7.189 6.818 14.379 13.635 21.568 20.453 C 77.788 69.973 80.881 66.89 78.837 64.952 L 78.837 64.952 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"></path>\
				<path d="M 14.446 68.039 c 7.189 -6.818 14.379 -13.635 21.568 -20.453 c 2.043 -1.938 -1.048 -5.022 -3.088 -3.088 c -7.189 6.818 -14.379 13.635 -21.568 20.453 C 9.315 66.889 12.406 69.974 14.446 68.039 L 14.446 68.039 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"></path>\
			</g>\
		</svg>');
		width: 18px;
		height: 18px;
	}
}

/*! purgecss end ignore */