.brands-list {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
    width: 100%;
    gap: 50px 25px;
    .brand-item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}