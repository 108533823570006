/*Page > Homepage */
/*! purgecss start ignore */
.cms-index-index {
	.container {
		max-width: var(--max-width-container);
		padding: 0 16px;
		position: relative;
		width: calc(100vw - 2rem);
		margin-left: auto;
		margin-right: auto;
	}

	.col-main .std {
		display: flex;
		flex-direction: column;
		gap: 16px;
		@media (min-width: 778px){
			gap: 60px;
			.descr {
				padding: 40px;
			}
		}
	}
	
	.trustinfo {
		display: flex;
		flex-direction: column;
		gap: 20px;
		@media (min-width: 778px){
			flex-direction: row;
			align-items: center;
		}
		justify-content: space-between;
		max-width: 100%;
		padding: 40px;

		background: gainsboro;
		& > * {
			svg {
				width: 50px;
			}
			display: flex;
			gap: 16px;
		}	
	}

	
	h2 {
		margin-bottom: 8px;
	}

	.wrap-subcat {
		&::after {
			display: none;
			/* content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 20%;
			height: 100%;
			background: linear-gradient(to right,rgba(1,1,1,0), currentColor);
			pointer-events: none; */
		}
	
		.sub-categories ol {
			/* .count {
				display: none;
			} */
			li a {
				& > img {
					border: none;
					max-width: 240px;
					filter: drop-shadow(6px 4px 10px rgba(0,0,0,.2));
				}
				& > div {
					max-width: 240px;
					.category-name {
						/* font-size: 16px;
						line-height: 18px; */
						font-size: 16px;
						line-height: 20px;
						/* font-size: 20px;
						line-height: 24px; */
					}
				}
			}
		}	
	}
}
.banner-promo {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	@media (min-width: 768px){
		display: grid;
		&.banner-promo__1_3 {
			grid-template-columns: repeat(3, 1fr);	
			& > *:nth-child(1) {
				grid-column: 1 / span 3;
			}
		}
		&.banner-promo__1_4 {
			grid-template-columns: repeat(4, 1fr);	
			& > *:nth-child(1) {
				grid-column: 1 / span 4;
			}
		}
		&.banner-promo__2 {
			grid-template-columns: repeat(2, 1fr);	
		}
		&.banner-promo__3 {
			grid-template-columns: repeat(3, 1fr);	
		}
		&.banner-promo__4 {
			grid-template-columns: repeat(4, 1fr);	
		}
	}
	
	h2 {
		@apply tw-text-4xl tw-font-bold tw-pb-2;
		filter: drop-shadow(0px 0px 6px rgba(0,0,0,.3));
		margin-bottom: 8px;
	}
	h3 {
		margin-top: 8px;
	}
	p {
		color: white;
	}
	a {
		color: white;
		:hover {
			color: white;
		}
		
		&.view-all {
			//text-decoration: underline;
			@extend .btn;
			@extend .btn-md;
			//@extend .btn-ui2;
			text-transform: uppercase;
			padding: 8px 16px !important;
			width: fit-content;
			justify-self: end;
			background-color: white;
			color: $color-text;
			&:after {
				margin-left: 4px;
				display: inline-block;	
				content: url('data:image/svg+xml,\
					<svg viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">\
						<path d="M1.97275 3.71249L4.68942 6.42916C5.01025 6.74999 5.53525 6.74999 5.85609 6.42916L8.57275 3.71249" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\
					</svg>');
				transform: rotate(-90deg);
				width: 12px;
			}
			&:hover {
				background: $color-text !important;
				color: white !important;
					
				&:after {
					content: url('data:image/svg+xml,\
					<svg viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">\
						<path d="M1.97275 3.71249L4.68942 6.42916C5.01025 6.74999 5.53525 6.74999 5.85609 6.42916L8.57275 3.71249" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\
					</svg>');
				}
			}
		}
	}
	picture {
		height: 100%;	
	}
	img {
		width: 100%;
		@media (min-width: 1024px){
			object-fit: cover;
			height: 100%;
		}
	}
	.wrap-subcat {
		.sub-categories ol {
			/* .count {
				display: none;
			} */
			li a {
				& > img {
					max-width: 124px;
					filter: drop-shadow(6px 4px 10px rgba(0,0,0,.2));
				}
				& > div {
					max-width: 124px;
					.category-name {
						font-size: 12px;
						line-height: normal;
					}
				}
			}
		}	
	}
}
/*Page > CMS*/

/*page 404*/

/* Customer */
.customer-account .col2-left-layout > .main {
	.col-main {
		order: 3;
	}

	.col-left {
		order: 2;
	}
}

/*! purgecss end ignore */