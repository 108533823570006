.post-list-container {
    ul {
        --grid-column-count: 1;
        @media (min-width: 778px){
            --grid-column-count: 4;
        }
        .post-list-item {
            box-shadow: 0 2px 15px 0 rgba(0,0,0,.1);
            .wrap-flex-sb {
                padding: var(--items-padding);
                .mc-post-info {
                    @extend .text-muted;
                }
            }
        }
    }
}
#post-widget-blog {
    --grid-item--max-width: 100%;
    @media (min-width: 768px) {
        --grid-item--max-width: 33.3333333333%;
    }
}
.posts-in-category ul {
    list-style: initial;
    margin: 10px 20px;
}
.readmore, .mc-read-more{
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    margin-top: 16px;
    &::after {
        display: block;
        content: url('data:image/svg+xml,\
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">\
            <path fill="white" fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd"></path>\
        </svg>');
        width: 18px;
        height: 18px;
    }
}