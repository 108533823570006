@font-face {
  font-family: 'Afacad';
  src: url('../fonts/afacad/Afacad-Bold.woff2') format('woff2'),
      url('../fonts/afacad/Afacad-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src: url('../fonts/afacad/Afacad-Italic.woff2') format('woff2'),
      url('../fonts/afacad/Afacad-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src: url('../fonts/afacad/Afacad-Regular.woff2') format('woff2'),
      url('../fonts/afacad/Afacad-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src: url('../fonts/afacad/Afacad-Medium.woff2') format('woff2'),
      url('../fonts/afacad/Afacad-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src: url('../fonts/afacad/Afacad-MediumItalic.woff2') format('woff2'),
      url('../fonts/afacad/Afacad-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src: url('../fonts/afacad/Afacad-SemiBold.woff2') format('woff2'),
      url('../fonts/afacad/Afacad-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src: url('../fonts/afacad/Afacad-BoldItalic.woff2') format('woff2'),
      url('../fonts/afacad/Afacad-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Afacad';
  src: url('../fonts/afacad/Afacad-SemiBoldItalic.woff2') format('woff2'),
      url('../fonts/afacad/Afacad-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}



  /* https://screenspan.net/fallback */
  
@font-face {
  font-family: 'Adjusted Arial Fallback';
  src: local(Arial);
  size-adjust: 89%;
  ascent-override: 116%;
  descent-override: 33%;
  line-gap-override: 4%;
}

$font-heading: 'Afacad', 'Adjusted Arial Fallback', sans-serif;
$font-text: 'Afacad', 'Adjusted Arial Fallback', sans-serif;

//1.FONT
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap');
$font-heading: 'Noto Sans', sans-serif;
$font-text: 'Noto Sans', sans-serif; */

/*@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap');
$font-heading: 'Poppins', sans-serif;
$font-text: 'Poppins', sans-serif;*/

/*@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap');
$font-heading: 'Nunito', sans-serif;
$font-text: 'Nunito', sans-serif; */

/*@import url('https://fonts.googleapis.com/css2?family=Outfit:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap');
$font-heading: 'Outfit', sans-serif;
$font-text: 'Outfit', sans-serif; */

/*@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&display=swap');
$font-heading: 'Cabin', sans-serif;
$font-text: 'Cabin', sans-serif; */

$font-xxs: 10px;
$font-xs: 12px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 18px;
$font-xl: 20px;
$font-xxl: 24px;

//2.COLOR
$color-brand: #0d2a4a;
//$color-cta: #31b529;
$bg-brand-gradient: black; 
//$bg-gradient-top: linear-gradient(270deg, #FFAD62 15.14%, #C361FF 52.33%, #4AC1CA 85.14%);
$bg-gradient-top: black;
//$bg-gradient-top: linear-gradient(270deg, #FFAD62 15.14%, #4AC1CA 85.14%);
$bg-wishlist: black; 
//$bg-cta-gradient: linear-gradient(0deg, rgba(255,107,0,1) 0%, rgba(249,129,43,1) 100%);
/* $color-cta: #FF6B00;
$bg-cta-gradient: linear-gradient(-180deg,  rgb(247, 136, 57)0%, rgb(223, 93, 0) 100%);
$bg-cta-gradient-highlight: linear-gradient(-180deg, rgb(247, 136, 57)0%, rgba(255, 107, 0, 1) 100%);
$bg-cta-gradient-hover: linear-gradient(-180deg, rgb(247, 136, 57)0%, rgba(255, 107, 0, 1) 100%); */

$color-cta: #000;
$bg-cta-gradient: #ff7a3a;
$bg-cta-gradient-highlight: #ff7a3a;
$bg-cta-gradient-hover: #ff933a;
//$bg-cta-gradient: linear-gradient(-180deg, #FF7E31, #ff5500);
$bg-cta-gradient-shadow-hover: rgba(253, 76, 0, 0.5) 0 0px 8px;

$color-brand-dark: #29A56C;
$color-brand-2: #ff8501;
$color-primary: #5a97fa;
$color-secondary: #f4f6fa;
$color-warning: #ff9900;
$color-danger: #FD6E6E;
$color-success: #81B13D;
$color-info: #2cc1d8;
$color-heading: #012242;
$color-grey-1: $color-heading;
$color-grey-2: #515154;
$color-grey-4: #adadad;
$color-grey-9: #f4f5f9;
$color-muted: #B6B6B6;
$color-body: #012242;
$color-text: #012242;


$color-1: #fddde4;
$color-2: #cdebbc;
$color-3: #d1e8f2;
$color-4: #cdd4f8;
$color-5: #f6dbf6;
$color-6: #fff2e5;
$color-7: #fddde4;
$color-8: #d1e8f2;
$color-9: #F2FCE4;
$color-10: #FFFCEB;
$color-11: #ECFFEC;
$color-12: #FEEFEA;
$color-13: #FFF3EB;
$color-14: #FFF3FF;
$color-15: #F2FCE4;

$color-hot: #f74b81;
$color-new: #55bb90;
$color-sale: #ff8300;
$color-best: #67bcee;

//3.Boxshadow
$box-sidebar: 10px 10px 20px rgba(0,0,0,.07);
$box-shadown-1: 10px 10px 20px rgba(0,0,0,.07);
$box-shadown-2: 0 4px 8px -2px rgba(67, 74, 171, 0.15),0 0 1px 0 rgba(67, 151, 171, 0.2);
$box-shadown-3: 20px 20px 54px rgba(0, 0, 0, 0.03);
$box-shadown-3-hover: 20px 20px 54px rgba(0, 0, 0, 0.05);


//4. Border
$border-color: #928F8F;
$border-color-2: #01679C;

$border-radius-0: 0px;
$border-radius-1: 4px;
$border-radius-2: 8px;
$border-radius-3: 16px;
$border-radius-4: 32px;
$border-radius-100: 50%;

//5. Background
$background-1: #F2F3F4;
$background-2: #F4F6FA;
$background-3: #DEF9EC;

//6.Button
$bg-addtocart: #FF6B00;


