
/*LAYOUT -> HEADER STYLE*/
/*Header default*/

.sidebar-active {
	overflow: hidden;
	.body-overlay {
  		opacity: 1;
  		visibility: visible;
		backdrop-filter: blur(4px);
  	}
}
.body-overlay {
  -webkit-transition: all .25s cubic-bezier(.645,.045,.355,1);
  -o-transition: all .25s cubic-bezier(.645,.045,.355,1);
  transition: all .25s cubic-bezier(.645,.045,.355,1);
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  cursor: crosshair;
  z-index: 999999;
}


@media (max-width: 1024px) {
	.skip-content {
		content-visibility: hidden;
	}
	.skip-active {
		content-visibility: visible;
	}
}




.page-header {
	/* box-shadow: 0 4px 10px 0 #00000029;
	border-bottom: 2px solid #d7d7d7; */
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
	/* border-bottom: 1px solid #f4f4f4; */
	padding: 2px 10px;
	/* min-width: calc(360px - 10px); */
	@media (min-width: 1024px) {
		display: flex;
		flex-direction: column;
		// gap: 16px;
		padding: 16px 0 0 0;
		.page-header-container {
			padding: 0 24px;
		}
	}
}
/*! purgecss start ignore */
.top-container {
	/* background: linear-gradient(-45deg, #73aa70, #447795); */
	//background: $color-body;
	/* background: #253d4e;
	background: #4d3264; */
	/*background: #50bdc8;*/
	background: $color-brand-2;
	.info_before_content {
		max-width: var(--max-width-container);
		margin: 0 auto;
		padding: 4px 0;
		color: #fff;
		@media (min-width: 1024px) {
			padding: 8px 0;
		}
		text-align: center;
		p {
			color: #fff;
			font-size: 14px;
			@media (min-width: 1024px) {
				font-size: 16px;
			}
		}
		a {
			/* text-decoration: underline; */
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
		}
		svg {
			margin: 0 4px;
			width: auto;
			height: 24px;
			aspect-ratio: 1/1;
		}
	}
}


.sidebar-active .sticky-bar.stick {
	display: contents;
}

body:not(.sidebar-active) .page-header {
	position: relative;
	z-index: 90;
}
body:not(.sidebar-active, .catalog-product-view, .cms-page-view, .checkout-cart-index) {
	
	z-index: 90;
	.sticky-bar {
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		z-index: 999999;
		.stick {
			.wrap-nav {
				display: none;
			}
			@media (min-width: 1024px) {
				width: 100%;
				.wrap-nav {
					display: initial;
				}
			}
		}
	}
	.header-sticked {
		.header-language-background {
			display: none;
		}
		.main-container {
			/* margin-top: 199px; */
		}
		.page-header-container .label {
			display: none;
		}
	} 
}

.page-header {
	// background: linear-gradient(to bottom, #fff, #fff, #fff, #fff, #fff, #fefefe, #fdfdfd, #fcfcfc, #fafafa, #f8f7f7, #f6f4f5, #f4f2f2) !important;
	background: #fff;
	//background: linear-gradient(to bottom,#fff,#fff,#fff,#fff,#fff,#fefdfe,#fcfcfc,#fbfafb,#f8f7f7,#f5f3f4,#f1f0f0,#e6e4e4) !important;
	// background-image: linear-gradient(to bottom, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #fefdfe, #fcfcfc, #fbfafb, #f8f7f7, #f5f3f4, #f1f0f0, #e6e4e4) !important;
	//background: linear-gradient(0deg, rgb(216, 255, 148) 0%, rgb(173, 247, 184) 24%, rgb(190, 242, 171) 25%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%) !important;
	//background: linear-gradient(0deg, #ffef98 0, #d6f298 20%, #bee36d 26%, #fff 50%, #fff 100%) !important;
	/* @media (min-width: 1024px) {
		background: #fff;
		background-image: linear-gradient(to bottom, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #fefdfe, #fcfcfc, #fbfafb, #f8f7f7, #f5f3f4, #f1f0f0, #eeeded) !important;
	} */
}

.sidebar-active .sticky-bar.stick {
	display: contents;
}
body:not(.checkout-cart-index, .catalog-product-view, .cms-page-view) .sticky-bar.stick {
	position: sticky;
	top: 0;

	//animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
	// box-shadow: 0 4px 8px -2px rgba(171,147,67,.15) , 0 0 1px 0 rgba(171,147,67,.2);
	//width: calc(100% - 12px);
	.wrap-nav {
		display: none;
	}
	@media (min-width: 1024px) {
		width: 100%;
		.wrap-nav {
			display: initial;
		}
	}
	z-index: 9998;
	left: 0;
	//-webkit-transition: all .3s ease 0s;
	//transition: all .3s ease 0s;
}
.header-sticked {
	.header-language-background {
		display: none;
	}
	.main-container {
		/* margin-top: 199px; */
	}
} 

/* .touch .header-sticked .page-header-container .label {
	display: none;
} */

.header-language-background {
	padding-left: 8px;
	padding-right: 8px;
	@media (min-width: 1024px) {
		padding-left: 24px;
		padding-right: 24px;
	}
	.top-info {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;

	}
}
.page-header-container > *,
.account-cart-wrapper > * {
	flex-grow: 1;
}
.page-header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
		
	gap: 8px;
	@media (max-width: 380px) {
		gap: 4px;
	}
	margin: 4px 0;
	@media (min-width: 1024px) {
		gap: 24px;
		margin: 0 auto;
		max-width: var(--max-width-container);
		width: calc(100% - 48px);
	}	
}
.page-header-container__nav {
	margin: 0 auto;
}

.page-header-container > nav {
	display: flex;
	flex-grow: 0;
	overflow: auto;
	@include scrollbar-mini;
	@media (min-width: 1024px) {
		display: initial;
		flex-grow: 1;
	}
	.nav-primary {
		align-items: center;
	}	
}

#header-account {
	.customer-form-mini-login {
		display: flex;
		flex-direction: column;
		gap: 72px;
	}
	.links {
		li > a {
			display: flex;
			align-items: center;
			gap: 8px;
			& > span {
				display: flex;
				align-content: center;
				align-items: center;
				justify-content: center;
				color: white;
				line-height: 0;
				font-size: 10px;
				font-weight: bold;
				border-radius: 50%;
				background: $bg-wishlist;
				width: 22px;
				height: 22px;
			}
			&.top-link-cart > span {
				background: $bg-cta-gradient-highlight;
			}
		}
	}
}
/*! purgecss end ignore */
//.skip-content:not(#header-nav) {
.skip-content {	
	visibility: hidden;
	position: fixed;
	@media (max-width: 1024px) {
		content-visibility: hidden;
	}
	//content-visibility: hidden;
	transform: translate(100vw);
	transition: transform .5s cubic-bezier(.645,.045,.355,1) , visibility .5s cubic-bezier(.645,.045,.355,1);

	
	box-shadow: $box-sidebar;
	width: 80vw;
	max-width: min(350px, 75%);
	height: calc(100vh - 32px);
	height: calc(100dvh - 32px);
	padding: 16px 24px;
	background: #fff;

	header {
		h6 {
			margin-top: 4px;
		}
	}
	&#header-cart {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

	}
	&#header-account {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
	&.block-layered-nav,
	&.block-account {
		display: flex;
		flex-direction: column;
		gap: 24px;
		@media (min-width: 1024px) {
			visibility: visible;
			transform: translate(0);
			position: relative;
			padding: 0;
			// border: 1px solid #ccc;
			// border-radius: 16px;
			// border-top-left-radius: 0;
			// border-bottom-right-radius: 0;
			// border-top-width: 2px;
			// border-right-width: 2px;
			box-shadow: none;
			background: transparent;
			z-index: 0;
			/* position: sticky;
			top: 150px;
			align-self: start; */
			height: auto;
			width: auto;
			max-width: none;
			.skip-link-close {
				display: none;
			}
		}
		.block-content {
			padding-bottom: 16px;;
		}
	}

	top: 0;
	right: 0;

	touch-action: manipulation;
	overflow: auto;
	overflow-x: hidden;
	z-index: 999999;
	border-radius: 0;


	&#header-nav {
		left: 0;
		transform: translate(-100vw);
		/* padding-top: 8px; */
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 24px;
		overflow-x: hidden;
		@media (min-width: 1024px) {
			header, footer {
				display: none !important;
			}
			visibility: visible;
			transform: translate(0);
			overflow: visible;
			position: relative;
			box-shadow: none;
			background: transparent;
			z-index: 0;
			/* padding-left: 0;
			padding-right: 0; */
			padding: 8px 0;
			height: auto;
			width: auto;
			max-width: none;
			.skip-link-close {
				display: none;
			}
		}
		main {
			flex-basis: 100%;
		}
		.logo img.small {
			display: block;
		}
		// .wrap-logo::after {
		//   display: block;
		//   content: '';
		//   height: 8px;
		//   background: $color-brand;
		//   max-width: 30%;
		//   border-radius: 8px;
		// }
		// @media (min-width: 1024px) {
		// 	visibility: visible;
		// 	transform: translate(0);
		// 	overflow: inherit;
		// 	position: relative;
		// 	z-index: 9;

		// 	flex-direction: row;
		// 	justify-content: space-between;

		// 	box-shadow: none;
		// 	padding: 0;
		// 	height: auto;
		// 	max-width: none;
		// 	width: auto;
		// 	width: auto;
		// 	// background: #04496c;
		// 	// background: $color-brand-2;
		// 	.skip-link-close {
		// 		display: none;
		// 	}
		// }
		footer {
			display: flex;
			flex-direction: column;
			gap: 16px;
			
			& a.btn {
				padding: 8px 16px;
				border: 2px solid $color-brand;
				border-radius: 8px;
				display: flex;
				align-items: center;
				gap: 8px;
				color: $color-brand;
				font-weight: bold;
				/* &::before {
					background-image: url(../imgs/theme/icons/account-icon.svg);
				  display: block;
				  content: '';
				  height: 24px;
				  width: 24px;
				  background-repeat: no-repeat;
				  background-position: center;
				} */
				p {
					color: inherit;
					font-weight: inherit;
				}
			}
			.account {
				display: flex;
				flex-direction: column;
				gap: 8px;
				
				.links ul {
					display:flex;
					flex-direction: column;
					gap: 4px;
					li {
						margin-left: 8px;
					}
				}

			}

			.support {
				display: flex;
				flex-direction: column;
				gap: 8px;
				margin-top: 12px;
			}
		}

	}



	&.skip-active {
		visibility: visible;
		transform: translate(0) !important;
		content-visibility: visible;
	}

	.close {
		position: absolute;
		top: 8px;
		right: 8px;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		color: black;
		line-height: 0;
		/* font-size: 48px;
		font-weight: bold;
		border-radius: 50%; */
		// background: $color-text;
		width: 32px;
		height: 32px;
		& svg {
			width: 32px;
			height: 32px;
		}
	}
}

.skip-sidebar {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 4px;
	@media (min-width: 1024px) {
		display: none;
	}

	/* background: $color-brand;
	color: white;
	// &:hover{
	// 	background-color: $color-brand;
	// }
	padding: 4px 8px;
	border-radius: $border-radius-2;
	font-weight: bold; */
	font-size: 14px;
	@extend .btn;
	@extend .btn-xs;
	@extend .btn-ui2;

	.count {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		color: $color-brand;
		line-height: 0;
		font-size: 12px;
		font-weight: 700;
		border-radius: 50%;
		background: white;
		width: 18px;
		height: 18px;
		&:empty {
			display: none;
		}
	}
	/* &:hover {
		color: white;
	} */
	// .icon,
	// svg {
	// 	height: 20px;
	// 	width: 20px;
	// 	// background-repeat: no-repeat;
	// 	// background-position: center;
	// 	//background-image: url('../imgs/theme/icons/filter.svg');
	// 	// margin-top: 4px;
    //     // margin-left: 4px;
    //     // display: inline-block;
        
    //     //background-image: url(../imgs/theme/icons/arrowdown2.svg);
    //     // border: 1px solid;
    //     content: url('data:image/svg+xml;utf8,\
	// 		<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="2" stroke="white">\
  	// 			<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />\
	// 		</svg>');
	// }
	// &:after {
		
	// 	height: 20px;
	// 	width: 20px;
	// 	// background-repeat: no-repeat;
	// 	// background-position: center;
	// 	// background-image: url('../imgs/theme/icons/arrowright2.svg');
	// 	content: url('data:image/svg+xml,\
	// 		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white">\
  	// 			<path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />\
	// 		</svg>');
	// }
}

.wrap-search-actions {
	display: contents;
	#header-search {
		margin-bottom: 4px;
		width: 100vw;
		width: 100dvw;
		// margin: 0 -5px;
		// background: #066197;
		// padding: 6px 6px;
		.input-box {
			justify-content: center;
			margin: 0;
		}
	}
}
@media (min-width: 1024px) {
	.wrap-search-actions {
		display: flex; 
		justify-content: space-between;
		align-items: center;
		flex-direction: row-reverse;
		gap: 32px;
		#header-search {
			width: auto;
			flex: 1;
			background: transparent;
			padding: 0;
			margin: 0;
		}
	}
}

.account-cart-wrapper {
	position: relative;
	display: flex;
	justify-content: end;
	align-items: center;
	flex-wrap: wrap;
	gap: 4px;
	@media (min-width: 480px) {
		gap: 6px;
	}
	@media (min-width: 900px) {
		gap: 14px;
	}
	
	& > * {
		max-width: 50px;
	}

}

.page-header-container {
	.icon,
	svg {
		color: $color-body;
		display: block;
		/* height: 30px;
		width: 30px; */
		/* @media (min-width: 887px) { */
			height: 34px;
			width: 34px;
		/* } */
		background-repeat: no-repeat;
		background-position: center;
	}
	.label {
		/*color: $color-text;*/
		color: #3a4855;
		font-size: 10px;
		line-height: 10px;
		@media (min-width: 1024px) {
			font-size: 14px;
			line-height: 14px;
		}
	}
}
.skip-nav {
	background: none;
	border: none;
	padding: 0;
	margin: 4px auto;
	// max-width: 30px;
	@media (min-width: 1024px) {
		// display: none !important;
	}
}
.skip-nav,
.skip-account,
.skip-cart,
.skip-wishlist,
.skip-help {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: min-content;
	gap: 2px;
	@media (min-width: 887px) {
		gap: 4px;
	}
	@media (hover: hover) {
		&:hover {
			text-decoration: none;
			color: $color-brand;
			.label {
				color: $color-brand;
			}
			/* svg {
				path {
					stroke: $color-brand;
				}
			} */
		}
	}
}

.skip-wishlist {
	display: none;
	@media (min-width: 412px) {
		display: inherit;
	}
}

.skip-wishlist:hover {
	color: #ff0083;
	.label {
		color: #ff0083;
	}
	svg {
		path {
			fill: #ff0083;
			stroke: #ff0083;
		}
	}

}

.skip-nav .icon {
	background-image: url('../imgs/theme/icons/burger.svg');
}
.skip-wishlist .icon {
	background-image: url('../imgs/theme/icons/wishlist.svg');
}
.skip-help .icon {
	background-image: url('../imgs/theme/icons/telephone.svg');
}
.skip-account .icon {
	background-image: url('../imgs/theme/icons/account-icon.svg');
}
.skip-cart {
	position: relative;
	.icon {
		background-image: url('../imgs/theme/icons/shoppingcart.svg');
	}
	.count {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		color: white;
		position: absolute;
		top: -4px;
		right: -2px;
		line-height: 0;
		font-size: 10px;
		font-weight: bold;
		border-radius: 50%;
		background: $bg-cta-gradient-highlight;
		width: 22px;
		height: 22px;
	}
}

.logo {
	// text-align: center;
	display: flex;
	justify-content: center;
	@media (min-width: 1024px) {
		text-align: left;
		max-width: 220px;
	}
	
	img,
	svg {
		width: auto;
		height: auto;
		max-width: 153px;
		/* flex-basis: 100%; */
		@media (min-width: 1024px) {
			/* flex: 1; */
			max-width: 196px;
			margin: 4px auto;
		}
	}

	img.small {
		max-width: 170px;
		margin: 0 auto;
		@media (min-width: 1024px) {
			display: none;
		}
	}
	img.large {
		display: none;
		@media (min-width: 1024px) {
			display: block;
		}
	}
}

.header-language-background {
	background: $color-brand;
	color: white;
	padding: 0 6px;
	@media (min-width: 887px) {
		padding: 0 24px;
	}
	.header-language-container {
		display: flex;
		justify-content: space-between;
		select,
		p {
			color: white;
			font-size: 12px;
		}
		a,
		a:hover {
			color: $color-brand;
		}
		p {
			// font-weight: bold;
		}
	}
}

/*! purgecss start ignore */
#header-search {
	label,
	.search-button {
		display: none;
	}
	.input-box {
		width: 100%;
	}
	form input {
		width: 100%;
		font-size: 14px;
		@media (min-width: 1024px) {
			font-size: 16px;
			max-width: max(900px, 60%);
		}
		color: $color-text;
		box-shadow: rgba(124, 58, 237, 0.08) 0px 3px 4px 0px;
		//border: 1px solid $color-body;
		//border: 1px solid #d7d7d7;
		box-shadow: inset 0 1px 3px rgba(124, 58, 237, 0.10);

		background-color: #fff;
		background-image: url(../imgs/theme/icons/search.png);
		background-position: 12px center;
		padding-left: 40px;
		@media (min-width: 1024px) {
			background-position: 20px center;
			padding-left: 50px;
		}
		
		background-repeat: no-repeat;
		background-size: 40px;
		/* border-radius: 50px; */
		//border-radius: 16px;
		&:focus {
			background: none;
			padding-left: 30px;
		}
	}
	
}
/*! purgecss end ignore */









