// /*SHOP*/

/* @media (min-width: 778px) {
	.category-image {
		position: relative;
		.wrap-subcat {
			position: absolute;
			bottom: 0;
			left: 20px;
		}
	}
} */

.wrap-subcat {

	margin: 20px 0;

	@include right-blur($color: #fff);

	@media (min-width: 890px) {
		&:after {
			display: none;
		}
	}

	h5 {
		@extend .text-muted;
		margin-bottom: 4px;
		//font-variant: all-small-caps;
		/* text-transform: uppercase; */
		font-size: 14px;
	}

	/* @extend .mt-1; */
	/* @extend .mb-1; */

	.sub-categories {

		&>nav {
			display: flex;
			flex-grow: 0;
			
		}
		
		ol {
			display: grid;
			gap: 10px 8px;
			grid-auto-flow: column;
			grid-auto-columns: max-content;
			overflow-x: auto;
			@include scrollbar-mini(transparent, transparent);
			scroll-snap-type: x mandatory;

			padding: 8px 4px;
			@media (min-width: 778px) {
				padding: 0;
			}

			/* @media (min-width: 778px) {
				display: flex;
				flex-wrap: wrap;
			} */

			&>li {
				background: white;
				border: 2px solid $color-body;
				border-left-width: 4px;

				scroll-snap-align: start;

				@media (min-width: 778px) {
					// compense scrollbar height
					margin-top: 8px;
				}

				/* border: 2px solid #ddd; */
				padding: 4px 8px;
				/* border-radius: 8px; */
				/* transition: border-color 0.2s ease-in-out; */
				/* background: #fff; */

				&:hover {
					border-color: $color-body;
					background: $color-body;
					& > a {
						color: white;
					}
				}

				a {
					color: $color-body;

					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 8px;
					@media (min-width: 778px) {
						gap: 16px;
					}
					
					text-align: center;
					line-height: 16px;

					.category-name {
						/* text-decoration: underline; */
						font-size: 14px;
						font-weight: 500;
						text-transform: uppercase;
					}
					.count {
						@extend .text-muted;
						font-size: 10px;
					}
				}


				&:last-child {
					margin-right: 24px;
				}
			}
		}
	}
}

.catalog-category-view .col2-left-layout {
	.products-grid {
		--grid-column-count: 4;
	}
}
.catalog-category-view .col2-left-layout>.main {
	/* flex-direction: column-reverse; */
}

.category-title {
	@extend .mb-2;
	h1 {
		text-transform: uppercase;
	}
}

.category-description {
	/* margin-bottom: 16px; */
	font-size: $font-xs;
	overflow: hidden;
  	height: 180px;
	@media (min-width: 778px) {
		font-size: $font-sm;
	}
}

.category-products {
	@extend .mt-4;
	@extend .mb-3;
}

.products-grid {
	display: flex;
	flex-direction: column;

	@media (min-width: 778px) {
		display: grid;
	}

	/*! purgecss start ignore */
	// &.products-grid--max-4-col {
	/**
	   * User input values.
	   */

	--grid-layout-gap: 8px;
	--grid-column-count: 2;
	--grid-item--min-width: calc(50vw - 32px);
	//--grid-item--min-width: calc(50vw);

	margin: 0 -8px;

	@media (min-width: 420px) {
		--grid-layout-gap: 16px;
		--grid-column-count: 5;
		//--grid-item--min-width: 260px;
		//--grid-item--min-width: 195px;
		--grid-item--min-width: 168px;
		margin: 0;
	}
	@media (min-width: 778px) {
		--grid-layout-gap: 22px;
		//--grid-column-count: 6;
	}
	
	/**
	* Calculated values.
	*/
	--gap-count: calc(var(--grid-column-count) - 1);
	--total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
	--grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));
	
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
	row-gap: var(--grid-layout-gap);
	column-gap: var(--grid-layout-gap);
	// }
	/*! purgecss end ignore */

	.item {
		//background: #fbfbfb;

		--items-padding: 0 8px 8px;

		@media (min-width: 778px) {
			//--items-padding: 0 16px 16px;
		}

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 8px;
		
		/* transition: border-color .15s ease-in-out;
		border-radius: 8px; */
		// border: 1px solid #f5f5f5;
		// background: #f5f5f5;
		border: 1px solid #eee;
		border-radius: 4px;
		//background: #fff;
		// box-shadow: 3px 3px 8px rgba(0,0,0,.05);
		overflow: hidden;
		
		.wrap-media {
			position: relative;

			.product-image {
				display: flex;
				/* width: 100%; */
				overflow: hidden;

				img {
					width: 100%;
					height: auto;
					overflow: hidden;
				}
			}

			.configurable-swatch-list {
				position: absolute;
				left: 16px;
				bottom: 8px;
				overflow: auto;
				scrollbar-width: none;
				width: calc(100% - 32px);
			}
		}

		.wrap-flex-sb {
			/* background: linear-gradient(0deg, rgb(244, 242, 242) 0%, rgba(221, 221, 221, 0) 100%); */
		}

		.product-info {
			padding: var(--items-padding);

			.product-name {
				@extend .t-truncate;
				@extend .t-truncate-3;
				// height: 38px;
				/* font-size: 14px; */
				/* line-height: 16px; */
				font-size: 14px;
				@media (min-width: 778px) {
					font-size: 16px;
					/* line-height: 22px; */
				}
				text-transform: capitalize;
				/* font-weight: 600; */
				& > * {
					/* color: #928f8f; */
					font-weight: normal;
				}
			}

			.desc {
				font-size: 12px;
				line-height: 16px;

				/* @media (min-width: 778px) {
					font-size: 14px;
					line-height: 18px;
				} */

				@extend .t-truncate;
				@extend .t-truncate-2;
				margin-top: 4px;
				// margin-bottom: 8px;
				/* @media (min-width: 778px) {
					margin-top: 8px;
				} */
			}

			.ratings {
				.amount {
					display: none;
					@media (min-width: 778px) {
						display: initial;						
					}
				}
				// margin-bottom: 8px;
			}
		}

		.price-box {
			display: grid;
			/* grid-template-areas: 'oldprice price'
			'saveprice saveprice'
			'infotax infotax'; */
			grid-template-areas: 'price oldprice saveprice'
			'infotax infotax infotax';
			align-items: center;
			.old-price {
				grid-area: oldprice;
				order: 1;
				margin-left: 8px;
			}
			/* .minimal-price-link, */
			.save-price {
				grid-area: saveprice;
				order: 3;
				display: flex;
				align-items: center;
				gap: 4px;
				margin-left: 8px;
				border-left: 1px solid #ddd;
				.price {
					margin-left: 8px;
				}
				.price-label {
					display: none;
				}
				/* display: none; */
			}
			.price,
			.special-price {
				grid-area: price;
				order: 2;
				margin: 0;
			}
			.minimal-price-link {
				display: none;
			}
			&>* {
				/* display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 8px; */
			}

			.price-label {
				display: none;
				line-height: 16px;
			}
			.info-tax {
				grid-area: infotax;
				line-height: 16px;
				font-size: 11px;
			}

			//@extend .mb-1;
		}

		.actions {
			padding: var(--items-padding);
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 8px;

			.btn-cart,
			.button {
				align-self: center;
				width: 100%;
				display: none;
				@media (min-width: 778px) {
					display: inherit;
				}
			}

			.availability {
				text-align: center;
				line-height: 1.8;
				display: block;
				padding: 8px 0;
				margin-bottom: 0;
			}

			.add-to-links {
				display: none;
			}
		}

		.configurable_options {
		}

	}
}
/*.catalog-category-view {
	.col1-layout .category-products .products-grid {
		@media (min-width: 778px) {
			--grid-column-count: 6;
		}
	}
}*/

// disable button cart product grid
/* .products-grid .item .actions .availability,
.products-grid .item .btn-cart {
	display: none !important;
} */


.products-list {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.item {
		display: flex;
		align-items: center;

		/* background: linear-gradient(0deg, rgb(244, 242, 242) 0%, rgba(221, 221, 221, 0) 100%); */
		border: 1px solid #f5f5f5;
		background: #fff;
		border-radius: 8px;
		overflow: hidden;

		.product-image {
			display: flex;
			align-self: start;
			overflow: hidden;

			@media (min-width: 778px) {
				min-width: max-content;
			}

			img {
				width: 100%;
				height: auto;
				max-width: 180px;
			}
		}

		.product-shop {
			flex-grow: 1;
			flex: 75%;

			@media (min-width: 778px) {
				flex: auto;
				align-self: start;
			}

			display: grid;
			grid-template-columns: 1fr;
			padding: 16px;

			@media (min-width: 778px) {
				grid-template-columns: 3fr 1fr;
				gap: 0 32px;
				padding: 16px;
			}

			.product-primary {
				.product-name {
					@extend .t-truncate;
					@extend .t-truncate-md-3;

					font-size: 14px;

					@media (min-width: 778px) {
						font-size: 18px;
					}

					text-transform: capitalize;
					font-weight: 600;
					margin-bottom: 8px;
				}

				.ratings {
					.rating-links {
						display: none;
						font-size: 12px;

						@media (min-width: 778px) {
							font-size: 13px;
						}
					}
				}
			}

			.desc {
				display: none;

				@media (min-width: 778px) {
					font-size: 14px;
					line-height: 16px;
					display: block;
				}

				margin-top: 8px;
				@extend .t-truncate;
				@extend .t-truncate-md-2;

			}

			.product-secondary {
				display: flex;
				flex-direction: column;
				justify-content: start;

				.price-box {
					display: flex;
					flex-direction: column;
					.old-price {
						order: 2;
					}
					.save-price {
						order: 3;
					}
					.price,
					.special-price {
						order: 1;
					}

					margin-bottom: 8px;

					&>* {
						display: flex;
						justify-content: space-between;
						// margin: 0;
					}

				}

				.btn-cart,
				.button {
					display: none;
					@media (min-width: 778px) {
						display: inherit;
					}
					//@extend .w-100;
				}

				.add-to-links {
					display: none;
				}
			}

		}

	}
}


/*! purgecss start ignore */

.block-product-slider {
	@extend .mt-2;
	.block-title {
		margin-bottom: 16px;
	}
	.block-subtitle {
		font-size: 14px;
	}
}

.block-product-slider {
	overflow: hidden;
	.block-content {
		max-width: 1920px;
		//@include right-blur($color: #fafafa, $width: 20%);
		position: relative;
		.products-grid:not(#widget-new-products) .item .product-name {
			font-size: 14px;
		}
		/* &:after {
			content: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.45496 9.96001L7.71496 6.70001C8.09996 6.31501 8.09996 5.68501 7.71496 5.30001L4.45496 2.04001' stroke='gainsboro' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 36px;
		} */

		.prev,
		.next {
			/* &[aria-disabled="true"] {
				display: none;
			} */
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 999;
			cursor: pointer;
			background-color: rgba(255, 255, 255, 0.90);
			transition: background-color .2s ease-in-out;
  			box-shadow: 0 1px 8px 0 rgba(0,0,0,.2);
			border-color: #928f8f;
			border-style: solid;
			padding: 2px;
			display: flex;
			svg {
				width: auto;
				height: 26px;
				fill: #000;
			}
		}
		.prev {
			border-width: 1px 1px 1px 0;
			left: 0;
		}
		.next {
			border-width: 1px 0 1px 1px;
			right: 0;
		}
	}
}

html {
	.block-content {
		.prev,
		.next {
			background-color: rgba(255, 255, 255, 0.90);
			transition: background-color .2s ease-in-out;
			&:hover {
				background-color: rgba(255, 255, 255, 1);
			}
		}
	}
}

html {
	// animation zoom only no-touch	
	.products-list,
	.products-grid {
		.item:not(.out-of-stock) {
			.product-image img {
				transition: transform .3s cubic-bezier(.68, -0.55, .27, 1.55);
			}
			//.actions {
				@media (min-width: 778px) {
					.btn-cart,
					.button {
						visibility: hidden;
					}
					&:hover {
						justify-content: end;
						.price-box {
							display: none;
						}
						.btn-cart,
						.button {
							visibility: visible;
							flex: 1;
							span {
								display: inherit;
							}
						}
					}
				}
			//}
			&:hover {
				.product-image img {
					transform: scale(1.2);
				}
			}
		}
	}

	// highlight product on hover lowlight out-of-stock
	.products-list,
	.products-grid {
		.item {
			&:not(.out-of-stock) {
				&:hover {
					// box-shadow: $box-shadown-2-hover;
					border-color: $color-brand;
				}
			}
		}
	}
}

// out-of-stock lowlight
.products-list,
.products-grid {
	.item {
		&.out-of-stock {
			opacity: 0.5;
			.btn-cart {
				display: none !important;
			}
		}
	}
}

#widget-new-products {
	--grid-item--min-width: 240px;
}

.slider {
	display: grid;
	grid-template-columns: unset;
	grid-auto-flow: column;
	grid-auto-columns: 100%;

	overflow-x: hidden;
	scroll-snap-type: x mandatory;

	& > * {
		scroll-snap-align: center;
		transition: all 0.2s;
	}
}

/*! purgecss end ignore */

/* Products Related */
.block-product-slider .products-grid {

	/* padding-right: 20%; */

	--grid-column-count: attr(data-columnCount);
	--grid-item--min-width: 120px;
	--grid-item--max-width: calc( 45vw - 16px);
	@media (min-width: 778px) {
		/* --grid-item--min-width: 120px; */
		--grid-item--max-width: 160px;
	}
	&#widget-slide-products {
		--grid-item--min-width: 80px;
		--grid-item--max-width: 80px;	
		--grid-layout-gap: 8px;
	}
	display: grid;
	grid-template-columns: unset;
	grid-auto-flow: column;
	grid-auto-columns: calc(max(var(--grid-item--min-width), var(--grid-item--max-width)) + var(--grid-layout-gap));

	overflow-x: auto;
	scroll-snap-type: x mandatory;

	& > * {
		scroll-snap-align: start;
		transition: all 0.2s;
		margin-bottom: 8px;
	}
	.item .price-box .save-price {
		display: none;
	}

	@include scrollbar-mini(transparent, transparent);
}

.price-box {
	.old-price {
		margin: 0;
		line-height: initial;
		font-size: 14px;
		color: $color-body;

		.price {
			text-decoration: line-through;
		}
	}

	.save-price {
		margin: 0;
		line-height: initial;
		font-size: 12px;

		@media (min-width: 778px) {
			font-size: 14px;
		}

		color: #B12704;

		// alternative 2
		// font-size: 12px;
		// color: #000;
		// background: #7fda69;
		// padding: 0 4px;
		.price {
			// text-decoration: line-through;
		}
	}

	.special-price {
		/* margin-top: 8px; */
		font-size: 14px;

		@media (min-width: 778px) {
			font-size: 16px;
			line-height: normal;
		}

		color: $color-brand;
		font-weight: bold;

		/*! purgecss start ignore */
		.price-label span {
			color: $color-brand-2;
		}

		/*! purgecss end ignore */
		.price {
			font-size: 18px;

			@media (min-width: 778px) {
				font-size: 20px;
			}
		}
	}
}

.ratings {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 0;

	.rating,
	.rating-box {
		height: 15px;
		background-repeat: repeat-x;
		background-image: url(../imgs/theme/rating-stars.png);
	}

	.rating-box {
		background-position: 0 -15px;
		width: 80px;
	}

	.rating {
		background-position: 0 0;
	}

	.amount {
		font-size: 12px;
	}
}

.no-rating {
	font-size: 12px;
	margin: 0;
}

.product-view .product-essential {
	.col2-set {
		flex-direction: column;
		gap: 24px;
		@media (min-width: 1280px) {
			gap: 32px;
			max-width: 650px;
			/* flex-direction: row; */
		}

		.col-1,
		.col-2 {
			width: 100%;
			max-width: 650px;
		}

		@media (min-width: 1280px) {
			.col-1 {
				width: 100%;
				max-width: 100%;
			}

			.col-2 {
				/* width: 60%; */
				/* min-width: fit-content !important; */
				min-width: 380px;
			}

		}
	}

	@media (min-width: 960px) {
		form {
			display: contents;
		}

		display: grid;
		grid-template-areas: 'imgbox shop'
		'imgbox addtocart'
		'imgbox collateral';
		grid-template-columns: min(750px, 50%) 47%;
		justify-content: space-between;

		gap: 0 3%;


		.product-img-box {
			grid-area: imgbox;
		}

		.product-shop {
			grid-area: shop;
		}

		.add-to-cart-wrapper {
			grid-area: addtocart;
		}

		.product-collateral {
			grid-area: collateral;
		}

		.col2-set {
			position: sticky;
			top: 10px;
			align-self: start;
			height: fit-content;
		}

	}

	.product-img-box {
		margin-left: -16px;
		margin-right: -16px;
		height: fit-content;
		margin-bottom: 16px;
		@media (min-width: 778px) {
			margin: 0;
			position: sticky;
			top: 10px;
		}
	}

	.product-img-box .product-image {
		position: relative;
		width: 100%;

		@media (min-width: 778px) {
			// box-shadow: $box-shadown-1;
			//border: 1px solid #eee;
			//border-radius: $border-radius-2;
		}

		overflow: hidden;

		.product-image-gallery {
			@media (min-width: 778px) {
				/* height: 500px; */
			}

			/* display: flex; */
		}
	}

	.product-img-box .product-name {
		display: none;
	}

	.product-img-box .product-image img {
		/* aspect-ratio: 1/1; */
		width: 100%;
		max-width: 100%;
		height: 100%;
		margin: 0px auto;
	}

	.more-views {
		margin: 8px 0;
		@media (min-width: 778px) {
			margin: 4px 0;
			.prev, .next {
				display: none;
			}
		}

		h2 {
			display: none;
		}

		.product-image-thumbs {
			display: flex;
			@media (min-width: 778px) {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: auto;
				grid-column-gap: 4px;
				grid-row-gap: 4px;
			}
			gap: 8px;
			margin-left: 8px;
			overflow-x: auto;
			scroll-snap-type: x mandatory;
			@include scrollbar-mini(transparent, transparent);
			padding-bottom: 8px;

			@media (min-width: 778px) {
				margin-left: 0;
			}

			&>li {
				flex-shrink: 0;
				// box-shadow: $box-shadown-1;
				border: 1px solid #eee;
				overflow: hidden;
				@media (min-width: 778px) {
					border: none;
					&:nth-child(1) {
						grid-area: 1 / 1 / 2 / 2;
					}
					&:nth-child(2) {
						grid-area: 1 / 2 / 2 / 3;
					}
					&:nth-child(3) {
						grid-area: 2 / 1 / 3 / 3;
					}
					&:nth-child(4) {
						grid-area: 3 / 1 / 3 / 3;
					}

				}

				a {
					user-select: none;
				}

				img {
					width: 100%;
					display: block;
				}
			}
		}
	}

	.product-image-gallery .gallery-image {
		display: none;
	}

	.product-image-gallery .gallery-image.visible {
		display: block;
	}

	.product-image-thumbs li:first-child {
		margin-left: -1px;
	}

	.product-image-thumbs li {
		display: inline-block;
	}

	.product-name .h1 {
		font-weight: 900;
		text-transform: capitalize;
	}

	.product-shop {
		.availability {
			display: inline-block;
			margin-bottom: 8px;

			padding: 4px 16px;
			font-weight: bold;

			&.in-stock {
				background: #def9f4;
				color: #3bb7a6;
			}

			&.out-of-stock {
				color: #f74b81;
				background: #fde0e9;
			}
		}

		.product-name h1 {
			text-transform: uppercase;
		}
	}

	
}

.sidebar-active .col2-set {
	position: initial !important;
}
.product-additional {
	margin-top: 80px;
}

.product-view .product-collateral {
	@extend .mt-5;
	@extend .mb-5;
}

.catalog-product-view .product-essential {
	.extra-info {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 4px;
		/* flex-direction: column; */

		@media (min-width: 778px) {
			gap: 16px;
			justify-content: flex-start;
			flex-direction: row;
		}
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.price-box {
		display: grid;
		grid-template-areas: 'oldprice saveprice'
		'price price'
		'infotax infotax';
		align-items: center;
		gap: 4px;
		max-width: fit-content;
		.old-price {
			grid-area: oldprice;
			order: 1;
			margin-right: 4px;
			
			font-size: 20px;
		}
		/* .minimal-price-link, */
		.save-price {
			grid-area: saveprice;
			order: 3;
			display: flex;
			align-items: center;
			gap: 4px;
			
			font-size: 14px;
			font-weight: 700;
			background: #B12704;
			color: white;
			padding: 2px 6px;

			.price-label {
				display: inherit;
			}
			.price {
				font-size: 14px;
				
			}
		}
		.price,
		.special-price {
			grid-area: price;
			order: 2;
			margin: 0;
			
			font-size: 20px;
			.price {
				order: 0;
				font-size: 24px;
			}
		}
		.minimal-price-link {
			display: none;
		}
		&>* {
			/* display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 8px; */
		}

		.price-label {
			display: none;
			line-height: 16px;
		}
		.info-tax {
			grid-area: infotax;
			line-height: 16px;
			font-size: 11px;
		}

		//@extend .mb-1;
	}


	/* .price-box {
		
		.price-label {
			display: none;
		}
		display: grid;
		grid-template-columns: 1fr 1fr;
		max-width: fit-content;
		gap: 4px 8px;
		align-items: center;

		// margin-bottom: 8px;
		&>* {
			//display: flex;
			align-items: center;
			//justify-content: space-between; 
			gap: 8px;
		}



		.old-price {
			order: 1;
			font-size: 14px;
		}

		.save-price {
			grid-column: span 2;
			order: 2;
			font-size: 16px;
			display: flex;
			.price-label {
				display: block;
			}
		}

		.special-price {
			order: 0;
			font-size: 18px;

			.price {
				order: 0;
				font-size: 24px;
			}
		}
	} */

	.add-to-cart {
		display: flex;
		gap: 8px;
		@media (min-width: 778px) {	
			/* display: grid;
			grid-template-columns: minmax(120px, max-content) 1fr; */
			gap: 16px;
		}

		.add-to-cart-buttons {
			flex-grow: 1;

			.btn,
			button {
				width: 100%;
			}

			.availability {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;
				font-size: $font-xs;

				.label {
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					text-indent: -9999px;

					&::after {
						line-height: 0;
						/* Collapse the original line */
						text-indent: 0;
						// display: block;
						// content: '';
						height: 16px;
						width: 16px;
						line-height: initial;
						/* New content takes up original line height */
						font-size: 18px;
						font-weight: bold;
						fill: $color-body;
						stroke: $color-body;
						background-repeat: no-repeat;
						background-position: center;
					}
				}

				&.in-stock .label::after {
					content: url('data:image/svg+xml,\
					<svg fill="green" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>checkmark</title> <path d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM23.258 12.307l-9.486 9.485c-0.238 0.237-0.623 0.237-0.861 0l-0.191-0.191-0.001 0.001-5.219-5.256c-0.238-0.238-0.238-0.624 0-0.862l1.294-1.293c0.238-0.238 0.624-0.238 0.862 0l3.689 3.716 7.756-7.756c0.238-0.238 0.624-0.238 0.862 0l1.294 1.294c0.239 0.237 0.239 0.623 0.001 0.862z"></path> </g></svg>');
				}

				&.out-of-stock .label::after {
					content: url('data:image/svg+xml,\
					<svg fill="red" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <g> <path d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z"></path> <path d="M64.2422,31.7578a5.9979,5.9979,0,0,0-8.4844,0L48,39.5156l-7.7578-7.7578a5.9994,5.9994,0,0,0-8.4844,8.4844L39.5156,48l-7.7578,7.7578a5.9994,5.9994,0,1,0,8.4844,8.4844L48,56.4844l7.7578,7.7578a5.9994,5.9994,0,0,0,8.4844-8.4844L56.4844,48l7.7578-7.7578A5.9979,5.9979,0,0,0,64.2422,31.7578Z"></path> </g> </g></svg>');
				}
			}
		}
	}

	.short-description {
		@extend .mt-2;
		font-size: 14px;
	}

	.product-attributes {
		@extend .mt-2;
		/* @extend .text-muted; */
		font-size: 12px;
		display: flex;
		flex-direction: column;
		gap: 4px;
		
		li > * {
			padding: 4px;
		}

		.label {
			font-weight: bold;
			background-color: $color-body;
			color: white;
		}
		.data {
			background-color: #eee;
		}
	}

	.add-to-cart-wrapper {
		// margin: 12px 0;
		// padding: 8px;
		//@extend .p-3;
		//@extend .mb-5;

		/* @media (min-width: 1336px) {
			padding: 32px;
		} */

		/* @media (min-width: 778px) { */
		/* border: 1px solid #ececec;
		border-radius: 15px;
		box-shadow: 1px 5px 5px rgba(0, 0, 0, .2); */
		//background: linear-gradient(0deg, #f4f2f2 0, rgba(221, 221, 221, 0) 100%);
		//background: linear-gradient(-180deg, rgb(244, 242, 242) 0%, rgba(221, 221, 221, 0) 100%);
		/* background: linear-gradient(0deg, rgb(244, 242, 242) 0%, rgba(221, 221, 221, 0) 100%); */
		/* background: #fff; */

		/* } */
		@media (min-width: 778px) {
			// max-width: 50%;
		}

		display: flex;
		flex-direction: column;
		gap: 16px;

		.price-info {
			//@extend .mb-1;
		}

		.price-box,
		.product-options-bottom {

			/* padding-bottom: 16px;
			border-bottom: 1px solid #eee; */
			.qty-wrapper {
				label {
					color: #7E7E7E;
					font-weight: normal;

					display: none;

					@media (min-width: 778px) {
						display: initial;
					}
				}

				.qty {
					max-width: 30px;
					aspect-ratio: 1;
					line-height: 50px;
					height: 48px;
					padding: 0 10px;
					&[type="number"] {
						max-width: initial;
						width: 60px;
					}
				}
			}
		}
		.product-options-bottom:not(.sticky) {
			/* .add-to-box {
				padding-bottom: 20px;
				border-bottom: 1px solid #eee;
			} */
			/* padding-bottom: 20px; */
		}

		.product-options {
			select,
			input[type="text"],
			textarea {
				width: 100%;
				/* &:not(:first-child) {
					margin-top: 8px;
				} */
			}
			.validation-advice {
				margin-top: 0;
			}
			.options-list > li:has(input[type="radio"], input[type="checkbox"]) {
				display: flex;
				gap: 8px;
			}
		}

		.availability {
			text-align: center;

			.label {
				font-weight: bold;
			}
		}
	}
}

/*! purgecss start ignore */
.product-options-bottom {

	&.sticky {
		position: fixed;
		bottom: 0;
		right: 0;
		max-width: 650px;
		min-width: calc(360px - 15px);
		width: calc(100% - 16px);

		padding: 8px !important;
		border-top: 1px solid #eee;
		box-shadow: 0 4px 10px 0 #00000029;
		background: linear-gradient(0deg, #eee 0, rgb(255, 255, 255) 100%);

		z-index: 9999;

		@media (min-width: 778px) {
			position: relative;
			width: auto;
			max-width: none;
			box-shadow: none;
			border: none;
			background: transparent;
		}
	}
}

/*! purgecss end ignore */
.add-to-links {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	font-size: 12px;

	.link-wishlist {
		display: inline-flex;
		align-items: center;
		gap: 2px;
		&:before {
			content: url('data:image/svg+xml,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" fill="none"><path d="M29.75 11.6875C29.75 8.16708 26.7764 5.3125 23.1087 5.3125C20.3674 5.3125 18.0129 6.90767 17 9.18425C15.9871 6.90767 13.6326 5.3125 10.8899 5.3125C7.225 5.3125 4.25 8.16708 4.25 11.6875C4.25 21.9158 17 28.6875 17 28.6875C17 28.6875 29.75 21.9158 29.75 11.6875Z" stroke="%231E1E1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>');
			width: 16px;
			height: 16px;
		}
		&:hover {
			color: #ff0083;
		}
	}
}

.link-learn {
	color: $color-body;
	font-size: 14px;
	font-weight: bold;
	text-decoration: underline;
	display: inline-flex;
	align-items: center;
	gap: 2px;
	&:after {
		content: url('data:image/svg+xml,\
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd"></path></svg>');
		width: 20px;
		height: 20px;
	}
}

.product-description {
	/* background-color: #eee;
	border-radius: 20px;
	padding: 40px; */
	h2 {
		margin-bottom: 8px;
	}
}

#product-review.skip-content {
	max-width: min(450px,75%);
}




.wrap-flex-sb {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.count-container {
	display: flex;
	// flex-direction: row-reverse;
	// flex-grow: 1;
	justify-content: space-between;
	align-items: center;
	gap: 16px;

	.amount {
		margin: 0;
		font-size: 12px;
		color: $color-muted;
		/* font-weight: bold; */

		span {
			color: $color-body;
		}
	}
}


.toolbar {
	display: flex;
	justify-content: space-between;
	
	@media (min-width: 778px) {
		flex-direction: row;
	}

	margin: 8px auto;
	gap: 8px;
	// & > * {
	// 	display: flex;
	// }
}

.sorter,
.pager {
	display: flex;
	.next-fastforward {
		display: none;
	}
	@media (min-width: 778px) {
		justify-content: space-between;
		align-items: center;
	}

	gap: 8px;

	color: $color-body;

	&>* {
		display: flex;
		align-items: center;
		gap: 8px;
		margin: 0;

		font-size: 12px;
	}
}

.pager {
	flex-direction: column;

	@media (min-width: 778px) {
		flex-direction: row;
	}
}

.view-mode {
	display: none;
}
/* .view-mode, */
.sort-by,
.limiter,
.pages ol {
	display: flex;
	align-items: center;
	gap: 8px;
	border-bottom: 1px solid #eee;
	border-right: 1px solid #eee;
	/* border: 1px solid #eee;
	background: #fff; */
	padding: 4px 8px;
	// box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
	font-size: 12px;

}

select,
/*.view-mode strong,
.view-mode a*/ {
	// margin-left: 8px;
	font-size: 12px;
	// border: 1px solid #ccc;
	// border-radius: $border-radius-2;
	// background: #fff;

}

/* .view-mode strong {
	color: $color-brand;
} */

label {
	// display: none;
	// @media (min-width: 778px) {
	// 	display: block;
	// }
	margin: 0;
	font-weight: bold;
	color: $color-body;
}

select,
p {
	font-size: inherit;
}

.sort-by {
	.sort-by-switcher {
		text-indent: -9999px;
		line-height: 0;

		/* Collapse the original line */
		&:after {
			text-indent: 0;
			display: block;
			line-height: initial;
			/* New content takes up original line height */
			font-size: 12px;
			font-weight: bold;
		}
		&:hover {
			text-decoration: none;
		}
	}

	.sort-by-switcher--asc:after {
		content: '↑';
	}

	.sort-by-switcher--desc:after {
		content: '↓';
	}
}

/* .view-mode-limiter {
	display: none;

	@media (min-width: 778px) {
		display: inherit;
	}

	// flex-direction: row-reverse;
} */


.category-products {
	.pages {
		display: none;
		&>strong {
			display: none;
			@media (min-width: 778px) {
				display: inherit;			
			}
		}
	}
}

.pages {
	/* display: none;
	
	&>strong {
		display: none;
		@media (min-width: 778px) {
			display: inherit;			
		}
	} */
	
	ol {
		/* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1); */
		padding: 8px 16px;
		
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;

		&>li>a,
		&>li {
			display: flex;
			align-items: center;
			justify-content: center;
			//background-color: #fff;
			//border: 1px solid $color-body;
			// font-weight: bold;
			width: 40px;
			height: 40px;
		}

		.current,
		&>li>a:hover {
			// border-color: $color-brand;
			background: $color-body;
			font-weight: bold;
			color: white;
			fill: white;
			stroke: white;
		}

		.next,
		.previous {
			text-indent: -9999px;
			line-height: 0;

			/* Collapse the original line */
			&:after {
				text-indent: 0;
				// display: block;
				// content: '';
				height: 20px;
				width: 20px;
				line-height: initial;
				/* New content takes up original line height */
				font-size: 18px;
				font-weight: bold;
				fill: $color-body;
				stroke: $color-body;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		.next:after {
			// background-image: url('../imgs/theme/icons/arrowright2.svg');
			content: url('data:image/svg+xml,\
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">\
					<path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />\
			</svg>');
		}

		.next:hover::after {
			// background-image: url('../imgs/theme/icons/arrowright2.svg');
			content: url('data:image/svg+xml,\
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white">\
					<path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />\
			</svg>');
		}

		.previous:after {
			// background-image: url('../imgs/theme/icons/arrowleft2.svg');
			content: url('data:image/svg+xml,\
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">\
					<path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />\
			</svg>');
		}

		.previous:hover::after {
			content: url('data:image/svg+xml,\
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white">\
					<path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />\
			</svg>');
		}
	}
}

.toolbar-bottom {
	.pager {
		gap: 16px;
		justify-content: space-between;
		@extend .mt-4;

		/* .count-container {
			display: none;
		} */
		.skip-links {
			display: none;
		}

		.next-fastforward {
			display: flex;
			justify-content: center;

			@extend .btn;
			@extend .btn-ui2;
			@extend .btn-md;
			padding: 10px 20px !important;
  			font-size: 14px;			
		}

		.pages {
			display: flex;
			justify-content: center;			
		}
	}

	.toolbar {
		display: none;
	}
}

/* .crosssell {
	@media (min-width: 778px) {
		max-width: 1400px;
	}
} */

.wrap-cart {
	.cart h6 {
		@extend .mb-3;
	}
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	@media (min-width: 968px) {
		flex-direction: row;
		justify-content: space-between;
		max-width: 1400px;
		gap: 64px;
	}

	/* margin: 32px auto; */

	aside {
		.page-title {
			display: none;
			@extend .mb-3;
			@media (min-width: 968px) {
				display: block;
			}
		}

		min-width: calc(360px - (16px * 2));

		@media (min-width: 968px) {
			flex: 0;
			align-self: start;
			position: sticky;
			top: 20px;
			min-width: 420px;
		}

		// position: sticky;
		// top: 10px;
		.theiaStickySidebar {
			// transform: none !important;
		}
	}

	&>.cart {
		@media (min-width: 778px) {
			flex: 1;
		}
	}

	.cart-table {
		.product-cart-image {
			border: 1px solid #eee;
		}
	}
}

.cart-table {

	td,
	th {
		padding: 0;
	}

	tr>* {
		border-width: 0;
	}

	display: flex;
	flex-direction: column;
	gap: 16px;

	tr {
		display: block;
	}

	thead {
		display: none;

		tr {
			// background: #eee;
			// & > th {
			// 	&:first-child {
			// 	 	border-radius: 20px 0 0 20px;
			// 	}
			// 	&:last-child {
			// 	 	border-radius: 0 20px 20px 0;
			// 	}
			// }
			// border-radius: 8px;
		}
	}

	tbody {
		display: flex;
		flex-direction: column;
		gap: 16px;

		&>tr {
			padding-bottom: 16px;
			display: grid;
			grid-template-columns: auto 1fr;
			align-items: center;
			gap: 8px 16px;

			@media (min-width: 1215px) {
				display: flex;
				gap: 16px;
			}


			.product-cart-price {
				display: none;
			}

			&:not(:last-child) {
				border-bottom: 1px solid #eee;
			}
		}

		.item-msg {
			color: #f06a6a;
			font-size: 12px;
			line-height: 14px;
			font-weight: bold;
			& + .cart-price-unit{
				margin-top: 4px;
			}
		}
	}

	.product-cart-image {
		& > td {
			position: relative;
		}
		position: relative;
		align-self: flex-start;
		overflow: hidden;
		.product-image img {
			display: block;
			max-width: 80px;

			@media (min-width: 1215px) {
				max-width: 100px;
			}

			height: auto;
		}

		img {
			// border: 1px solid black; 
		}

		.cart-links {
			position: absolute;
			bottom: 0;

			@media (min-width: 1215px) {
				bottom: 8px;
			}

			left: 8px;
			gap: 8px;
			display: flex;
			flex-direction: row-reverse;
		}
	}

	.product-name {
		flex: 1;
	}

	.product-cart-remove a,
	.link-wishlist {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		border-radius: 50%;
		border: 1px solid #eee;
		width: 24px;
		height: 24px;
		box-shadow: $box-shadown-2;
	}

	.product-cart-info {
		flex: 1;

		display: flex;
		flex-direction: column;

		.product-cart-sku {
			@extend .text-muted;
			@extend .font-xs;

			.label {
				font-weight: 600;
			}
		}

		.item-options {
			dt {
				font-weight: 600;
			}

			dt,
			dd {
				@extend .text-muted;
				@extend .font-xs;
			}
		}

		.cart-price-unit {
			display: flex;
			align-items: center;
			gap: 4px;

			&__qty {
				@extend .text-muted;
				@extend .font-xs;
			}

			&__price .price {
				@extend .font-sm;
			}
		}
	}

	.product-cart-actions {
		width: 80px;

	}

	.qty-wrapper {

		// flex-direction: column;
		.quantity-button {
			position: relative;
			@extend .btn-xs;
			@extend .btn-ui2;
		}

		.cart-item-quantity:focus+.quantity-button {}
	}

	.product-cart-total {
		width: 70px;
		text-align: left;

		@media (min-width: 1215px) {
			text-align: right;
		}

		.price {
			color: $color-body;
			font-weight: bold;
			@extend .font-md;
		}
	}


	.cart-footer-actions {
		display: flex;
		justify-content: space-between;
	}
}

.cart-totals>table {
	margin: 0;
	// tbody {
	// 	display: flex;
	// 	flex-direction: column;
	// 	gap: 8px;
	// 	tr {
	// 		width: 100%;
	// 		display: flex;
	// 		justify-content: space-between;
	// 		align-items: center;
	// 		td:first-child {
	// 			flex: 0 0 100%;
	// 		}
	// 	}
	// }
	td {
		border: none;
		color: $color-body;
		font-size: $font-md;

		// padding: 0;
		&:first-child {
			text-align: left;
		}
	}

	tbody tr {
		&:not(:last-child) {
			border-bottom: 1px solid #eee;
		}

		&:first-child td {
			/* padding-top: 0; */
		}

		td {
			line-height: $font-sm;
			padding: 8px 16px;
			font-size: $font-sm;
		}
	}

	tfoot td {
		color: $color-text;
		font-size: $font-lg;

		.price {
			font-size: 20px;
		}
	}
}


.cart-forms {
	/* margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid #eee; */
	text-align: center;
}

.minicart-message {
	display: none;
	position: relative;
	padding: 8px 16px;
	margin-top: 8px;
	border: 1px solid transparent;
	border-top-color: transparent;
	border-right-color: transparent;
	border-bottom-color: transparent;
	border-left-color: transparent;
	border-radius: 8px;
}

#minicart-success-message {
	color: $color-success-msg;
	background-color: $bg-success-msg;
	border-color: $border-success-msg;
}

#minicart-error-message {
	color: $color-error-msg;
	background-color: $bg-error-msg;
	border-color: $border-error-msg;
}

.block-cart.skip-content {}

.qty-wrapper {
	display: flex;
	align-items: center;
	align-self: start;
	gap: 8px;

}

.cart-item-quantity,
input.qty {
	padding: 8px;
	height: auto;
	text-align: center;
	max-width: 24px;
	aspect-ratio: 1;
	background: #fff;
}

.qty-sm {
	/* height: auto !important; */
	max-width: 26px !important;
	aspect-ratio: 1;
	font-size: 14px !important;
}

.quantity-button {
	visibility: hidden;
	opacity: 0;
}

.cart-item-quantity:focus+.quantity-button {
	visibility: visible;
	opacity: 1;
}

.minicart-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;

	max-height: 100%;

	main {
		border-bottom: 1px solid #eee;
		overflow: auto;
		@include scrollbar-mini;

		.mini-products-list {
			display: flex;
			flex-direction: column;

			// gap: 8px;
			&>.item {
				border-bottom: 1px solid #eee;
				padding: 16px 0;

				table {
					margin: 0;
				}
			}
		}

		.product-cart-remove svg {
			width: 16px;
			height: 16px;
		}

		.product-cart-info {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-self: start;

			.product-name {
				line-height: 16px;
				@extend .t-truncate;
				@extend .t-truncate-2;
			}

		}
	}

	footer {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 4px;

		.subtotal {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.label,
			.price {
				font-size: $font-md;
			}

			.price {
				font-weight: bold;
			}
		}

		.minicart-actions {
			display: flex;
			// flex-direction: column;
			align-items: center;
			gap: 8px;

			.button {
				width: 100%;
			}
		}
	}
}

// #shopping-cart-totals-table {
//   td {
//     border: none;
//     vertical-align: middle !important;
//     color: $color-body;
//     font-weight: normal !important;
//   }
//   td:first-child {
//     @extend h6;  
//     text-align: left;
//   }
//   .price {
//     @extend h6;
//     color: $color-body;
//   }
//   tfoot {
//     .price {
//       color: $color-brand;
//     }
//   }
//   @media only screen and (max-width:768px){
//     tbody, tfoot {
//       display: flex;
//       flex-direction: column;
//       tr {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//       }
//     }
//   }

// }
/*! purgecss start ignore */
.cart-totals-wrapper {
	/* background: linear-gradient(0deg, rgb(244, 242, 242) 0%, rgba(221, 221, 221, 0) 100%); */
	background: #fff;
	//padding: 10px;

	/* @media only screen and (min-width:377px) {
		padding: 24px;
	} */

	@media only screen and (min-width:1200px) {
		padding: 24px;
		border: 1px solid #ececec;
		box-shadow: 5px 5px 15px rgba(0, 0, 0, .05);
	}

	margin-bottom: 32px;

	.cart-totals {
		&:not(.sticky) {
			.method-checkout-cart-methods-fire {
				padding-bottom: 24px;
				border-bottom: 1px solid #eee;
			}
		}
		
		.method-checkout-cart-methods-paypalpaylater_after {
			display: block;
			margin: 8px 4px;
		}
		
		&.sticky {


			table,
			.paypal_paylater_banner-cart {
				margin: 8px auto !important;
			}

			.paypal_paylater_banner-cart .message__container {
				text-align: center !important;
			}

			.checkout-types.bottom {
				display: flex;
				flex-direction: column-reverse;
			}

			/* colgroup,
			tbody {
				display: none;
			} */

			tfoot td {
				padding: 4px;
			}

			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			/* max-width: 650px; */
			min-width: calc(360px - 15px);
			width: calc(100% - 16px);

			padding: 8px !important;
			border-top: 1px solid #eee;
			box-shadow: 0 4px 10px 0 #00000029;
			background: linear-gradient(0deg, #eee 0, rgb(255, 255, 255) 100%);

			z-index: 9999;

			@media (min-width: 778px) {
				position: relative;
				width: auto;
				max-width: none;
				box-shadow: none;
				border: none;
				background: transparent;
			}
		}
	}
}

// highlight totals   
#minicart-wrapper footer,
#shopping-cart-totals-table tfoot/*,
.catalog-product-view .regular-price,
.catalog-product-view .special-price*/
, #checkout-review-table tfoot tr:last-child
{
	.price {
		display: inline-block;
		position: relative;
		z-index: 1;
		padding: 0 0.5rem;
		&::before{
			background: #f7fb78;
			bottom: 20%;
			content: "";
			height: 50%;
			left: 0;
			opacity: .5;
			position: absolute;
			width: 100%;
			z-index: -1;
		}
	}
}

/*! purgecss end ignore */

.btn-proceed-checkout,
.catalog-product-view .product-essential .btn-cart,
.checkout-button {
	color: #fff;
	position: relative;
	/* padding: 0px 40px; */
	/* line-height: 1; */
	/* border-radius: 8px; */
	//border: 1px solid $color-brand-2 !important;
	/* line-height: 50px; */
	font-weight: 700;
	&:not(.checkout-button) {
		height: 50px;
		font-size: $font-lg;
	}
	font-family: $font-heading;
	text-transform: uppercase;
	width: 100%;
	transition: all .25s cubic-bezier(.02, .01, .47, 1);
	/* box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px; */
	@media (hover: hover) {
		&:hover {
			transform: translateY(-5px);
			/* box-shadow: $bg-cta-gradient-shadow-hover; */
		}
	}

	i {
		margin-right: 10px;
	}
}

.trustinfo {
	max-width: 650px;
	padding: 0 1rem;

	@media only screen and (max-width:768px) {
		.row {
			display: block !important;

			.col-6 {
				width: 100%;

				&:last-child {
					margin-top: 10px;
				}
			}
		}
	}
}

.cart-totals-wrapper .trustinfo {
	
	span,
	strong,
	div {
		font-size: $font-sm;
	}
	
	img {
		width: auto;
		height: 24px;
	}
	
	max-width: 380px;
	
	.row {
		display: block !important;
		
		.col-6 {
			width: 100%;
			
			&:last-child {
				margin-top: 10px;
			}
		}
	}
}
/*! purgecss start ignore */
.header-minicart .cart-trust-info {
	svg {
		width: max-content;
		height: auto;
	}
}
.trust-info {
	hr {
		height: 1px;
		width: 100%;
		background: #eee;
		border: none;
	}
	.trust-info__content {
		gap: 2px;
		width: 100%;
	}
}
/*! purgecss end ignore */

/*! purgecss start ignore */
.product-trustinfo {
	border-top: 1px solid #eee;
	padding-top: 32px;
}
/* .product-trustinfo {
	box-shadow: 0 1px 1px 0 rgba(0,0,0,.02);
	background: rgba(255,203,0,.03);
  	border: 1px solid #ffcb00;
	border-radius: 8px;
	padding: 14px;
} */
.product-trustinfo,
.cart-trust-info {	
	margin-top: 16px;
	color: $color-grey-2;
	line-height: 16px;
	img {
		margin: 4px 0;
		/*-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		mix-blend-mode: darken;*/
	}
	.trust-info__payment {
		/* svg {
			height: 24px;
		} */
	}
}
.paypal_paylater_banner-product {
	margin: 8px auto 0 auto !important;
	text-align: center;
	/* box-shadow: 0 1px 1px 0 rgba(0,0,0,.02);
	background: #fbfbfb;
	border: 1px solid #f7f7f7;
	padding: 14px;
	border-radius: 8px; */
	span {
		display: flex;
	}
}
.shipping-countdown {
	/* box-shadow: 0 1px 1px 0 rgba(0,0,0,.02);
	background: #e9ffef;
  	border: 1px solid #8eff8e;
	padding: 14px;
	border-radius: 8px; */
	width: max-content;
}
/*! purgecss end ignore */

.bw-img {
	img {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		mix-blend-mode: darken;
	}
}

.rounded-mini-badges {
	img {
		margin: 4px;
		border: 1px solid #f6f9ff;
		background-color: #fff;
		border-radius: 2px;
		box-shadow: 0 0 1px 1px #f6f9ff;

	}
}