/* .turbolinks-progress-bar {
	// height: 5px;
	// background-color: $color-brand;
} */


:root {
	--max-width-container: 1600px;	
}
// TEST WITHOUT ALERT
body:not(.cms-index-index) {
	
	.main-container {
		max-width: var(--max-width-container);
	}

	.main {
		margin: 8px 16px;
		@media (min-width: 1024px) {
	    	margin: 16px;
	  	}
	}

	/* .page {
		// background: #fafafa;
	} */
}

/*! purgecss start ignore */
.customer-account-forgotpassword,
//.customer-account-create,
//.contacts-index-index,
.sales-guest-form,
.catalogsearch-advanced-index {
	.main-container {
		max-width: fit-content !important;
	}
	.main {
		margin-top: 32px;
	}
	
}
/* .contacts-index-index {
	.col2-set {
		gap: 3%;
	}
} */
/*! purgecss end ignore */

.demo-notice {
	display: none;
}

.wrapper {
	min-width: 360px;
	margin: 0 auto;
}



.main-container,
.footer-container {
	margin: 0 auto;
}

.page {
	// background: #f7f7f7;
	.page-header {
		/* background: #fafdfd; */
	}
}
/*! purgecss start ignore */

.breadcrumbs {
	grid-area: header; 
	padding-bottom: 8px;
	@media (min-width: 1024px) {
		margin-bottom: 16px;
		padding-bottom: 0;
		margin-top: 8px;
	}
	/* border-bottom: 1px solid #ccc; */
	ul {
		display:flex;
		flex-wrap: wrap;
		flex-direction: column;
		@media (min-width: 1024px) {
			flex-direction: row;
		}
		--bread-fs: 14px;
		font-size: var(--bread-fs);
		& > li {
			
			/* font-size: 13px;
			line-height: 10px; */
			font-size: var(--bread-fs);
			line-height: inherit;
			//font-variant: all-small-caps;
			//text-transform: uppercase;
			display: none;
			align-self: start;
			align-items: center;
			&:nth-last-child(2) {
				display: flex;
				flex-direction: row-reverse;
				svg {
					transform: rotate(180deg);
					margin-right: 8px;
				}
			}
			&.product {
				display: none;
				/* margin-top: 8px;
				font-size: 16px;
				line-height: inherit; */
			}
			@media (min-width: 1024px) {
				display: flex;
				align-self: center;
				&:nth-last-child(2) {
					flex-direction: row;
					svg {
						transform: none;
					}
				}
				&.product {
					display: flex;
					margin-top: 0;
					font-size: inherit;
					line-height: inherit;
				}
				
				/* font-size: 14px;
				line-height: 12px; */
				strong {
					// color: $color-brand;
					font-weight: normal;
				}
				svg {
					margin: 0 4px;
				}
			}
			

			svg {
				display: inline-block;
				height: 12px;
				width: 12px;
				// @media (min-width: 1024px) {
				// 	height: 12px;
				// 	width: 12px;
				// }
				stroke: $color-body;
			}
		}
	}
}
.col2-left-layout > .main {
	display: flex;
	flex-direction: column;
	.breadcrumbs {
		order: 1;
	}
	.col-main {
		order: 2;
	}
	.col-left {
		order: 3;
	}
	@media (min-width: 1024px) {
		display: grid;
		grid-template-areas:
			'header header header header' 
			'left   main   main	  main';
		grid-template-columns: 1fr 4fr;
		gap: 0 2%;
		.col-left {
			grid-area: left; 

			position: sticky;
			top: 170px;
			align-self: start;
			height: fit-content;

		}
		.col-main {
			grid-area: main; 
		}
		.col-left:last-child {
			display: none;
			grid-area: right; 
		}
	}
}
.col3-layout > .main {
	.col-wrapper {
		display: contents;
	}
	@media (min-width: 1024px) {
		display: grid;
		grid-template-areas:
			'header header header' 
			'left   main   right ';
		grid-template-columns: 1fr 4fr 1fr;
		gap: 0 32px;

		.col-left {
			grid-area: left; 
	
			position: sticky;
			top: 150px;
			align-self: start;
			height: fit-content;
		}
		.col-main {
			grid-area: main; 
		}
		.col-right {
			grid-area: right; 
		}
		.col-left:last-child {
			display: none;
			grid-area: right; 
		}
	}
}

.htmx-request,
.htmx-swapping {
	pointer-events: none;
	position: relative;
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		
		display: grid;
		justify-content: center;
		align-content: center;
	}
	&:before {
		z-index: 99;
		content: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" id="layered_nav__LoadingIndicator"><defs><linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a"><stop stop-color="%23fff" stop-opacity="0" offset="0%"/><stop stop-color="%23fff" stop-opacity=".631" offset="63.146%"/><stop stop-color="%23fff" offset="100%"/></linearGradient></defs><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)"><path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(%23a)" stroke-width="2"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/></path><circle fill="%23fff" cx="36" cy="18" r="1"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/></circle></g></g></svg> ');
		-webkit-transition: all .25s cubic-bezier(.645,.045,.355,1);
		-o-transition: all .25s cubic-bezier(.645,.045,.355,1);
		transition: all .25s cubic-bezier(.645,.045,.355,1);
		background: radial-gradient(rgba(255,255,255,.2), transparent);
		backdrop-filter: blur(1px);
	}
}
.htmx-swapping {
	opacity: 0;
	transition: opacity 1s ease-out;
}
.htmx-added {
	opacity: 0;
	transition: opacity 1s ease-out;
}
/*! purgecss end ignore */