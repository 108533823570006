/*! purgecss start ignore */
// cookiebot
.ch2-block {
  z-index: 999999 !important;
}
.cms-index-index .show-all {
  // display: none;
}

.cms-page-view {
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6,
  .page-title {
    margin-bottom: 16px;
  }
  .std {
    ol {
      list-style: decimal;
    }
    ul {
      list-style: inherit;
    }
    ol, ul {
      margin: 10px 20px;
      li {
        @extend .font-sm;
        line-height: 1.6;
        padding: 2px 0;
      }
    }
    a {
      text-decoration: underline;
      color: $color-brand;
    }
    dl {
      dt > a {
        text-decoration: underline;
        color: $color-text;
      }
      dd {
        margin-bottom: 16px;
        p {
          margin: 8px 0;
        }
      }
    }
  }
  &.cms-condizioni-acquisto {
    .std {
      ol {
        li {
          padding: 2px 0;
          a {
            font-size: $font-md;
            line-height: 1.6;
            text-decoration: underline;
            color: $color-body;
          }
        }
      }
    }    
  }
}


.benefits {
  list-style: none;
  margin: 8px 4px;
  padding: initial;
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > li {
    display: flex;
    align-items: start;
    gap: 6px;
    &::before {
      display: block;
      aspect-ratio: 1/1;
      border: solid $color-body;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
      border-width: 0 1px 1px 0;
      content: "";
      height: 14px;
      margin: 0px 6px 0;
      transform: rotate(45deg);
      width: 8px;
    }
}
}
body:not(.firecheckout-index-index) {
  .col2-set,
  .box-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (min-width: 778px) {
      flex-direction: row;
    }
    /*gap: 8em;*/
    margin-bottom: 1em;
    .box {
      flex-basis: 100%;
    }
    .col-1, .col-2 {
      // fix bootstrap col width 8.33%
      width: auto;
      @media (min-width: 778px) {
        width: 50%;
      }
      flex: auto;
      //.box {
        // margin-top: 1em;
        h3 {
          font-size: 18px;
        }
        h4 {
          font-size: 16px;
        }
        a {
          //text-decoration: underline;
        }
      //}
    }
  }
}

.addresses-list {
  .item {
    margin-top: 1em;
    h3 {
      font-size: 18px;
    }
    a {
      text-decoration: underline;
      color: $color-brand;
    }
  }
}
.my-account {
  .welcome-msg {
    margin: 2em 0;
    .hello {
      font-size: $font-lg;
    }
  }
}

.customer-account {
  .skip-sidebar {
    margin-bottom: 8px;
  }
  .main .button {
    @extend .btn;
    @extend .btn-md;
    @extend .btn-ui2;
  }
  .buttons-set {
    .button {
      font-size: $font-md;
      @extend .wide;
    }
  }
}

.customer-account-index,
.sales-order-history,
.checkout-onepage-success {

  #my-orders-table {

    margin: 8px;
    
    display: table;
    thead {
      display: none;
      @media (min-width: 778px) {
          display: table-header-group;
        }
    }
    & tr:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    tbody tr {
      display: grid;
      grid-template-rows: auto;
      grid-template-areas: 
        "number date date"
        "ship ship ship"
        "status total total"
        "view view view";
      border-radius: 8px 8px 0 0;
      .number {
        grid-area: number;
        
      }
    
      .date {
        grid-area: date;
      }
      .ship {
        grid-area: ship;
      }
      .total {
        grid-area: total;
      }
      .status {
        grid-area: status;
      }
      .view {
        background: #aeb7bd;
        @media (min-width: 778px) {
          background: inherit;
        }
      }
    
      td::before {
        content: attr(data-rwd-label);
        display: block;
        font-size: $font-xs;
        text-transform: uppercase;
      }
      @media (min-width: 778px) {
        td::before {
          display: none;
        }
      }
    
    
      .view {
        grid-area: view;
        text-align: center;
        .nobr {
          display: flex;
          justify-content: end;
          gap: 8px;
          @media (max-width: 778px) {
            width: 100%;
          }
          .separator {
            display: none;
          }
        }
      }
    
      margin: 16px auto;
      background: #f5f5f5;
      @media (min-width: 778px) {
        background: inherit;
        &:nth-child(2n) {
          background: #f5f5f5;
        }
        display: table-row;
        margin: 0;
      }
    }
    
    em {
      /* @extend .btn;
      @extend .btn-xs;
      @extend .btn-outline; */
      font-weight: bold;
      &[data-status="complete"] {
        color: $color-success-msg;
      }
      &[data-status="canceled"] {
        color: $color-error-msg;
      }
      &[data-status="processing"] {
        color: $color-notice-msg;
      }    
    }
    td.view {
      a {
        @extend .btn;
        @extend .btn-xs;
        @extend .btn-ui;
        &.link-reorder {
          @extend .btn-ui2;
        }
      }
    }
    .last > .nobr {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        a {
          min-width: 120px;
          //@extend .button;
          @extend .btn;
          @extend .btn-xs;
          @extend .btn-ui;
          &.link-reorder {
            @extend .btn-ui2;
          }
        }
        .separator {
          display: none;
        }

    }
  }
}


 .customer-account {
  .order-info {
    dt {
      display: none;
    }
    dd > ul {
      display: flex;
      flex-wrap: wrap;
      font-size: 16px;
      gap: 16px;
      margin: 16px auto;
      li {
        color: $color-brand;
        font-weight: 900;
        text-transform: uppercase;
      }
      li.current {
        position: relative;
        &:after {
          border-bottom-color: inherit;
          border-bottom-style: solid;
          border-bottom-width: 1px;
          bottom: 0px;
          content: "";
          display: block;
          height: 0;
          left: 0;
          position: absolute;
          transition: border .15s ease-in-out;
          width: 100%;
        }
      }
    }
  }
}

.sales-order-view {
  .order-date {
    margin: 8px auto;
  }
}

#header-account main,
#account-navigation main,
.customer-account .order-info dd > ul {
  a {
    transition: color .15s ease-in-out;
    position: relative;

    &:after {
      border-bottom-color: transparent;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      bottom: -3px;
      content: "";
      display: block;
      height: 0;
      left: 0;
      position: absolute;
      transition: border .15s ease-in-out;
      width: 100%;
    }

    &:hover {
      text-decoration: none;

      &:after {
        //border-bottom-color: inherit !important;
      }
    }
  }

  & ul > li {
    margin-bottom: 4px;
    &.current a:after {
      border-bottom-color: inherit !important;
    }
  }
}

#wishlist-table {
  
  .customer-wishlist-item-info textarea {
    width: 80%;
    height: 80px;
  }
}

.nobr {
    display: inline-block;
    width: max-content;
}

// .box-account {
  .box-head,
  .box-title,
  .title-buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;      
    gap: 4px;
    @media (min-width: 778px) {
      gap: 8px;
      flex-direction: row;
    }
    align-items: start;
    margin-bottom: 8px;
  }

  .box-title,
  .title-buttons {
    a {
        // @extend .btn;
        //@extend .button;
        //@extend .btn-xs;
        @extend .btn;
        @extend .btn-xs;
        @extend .btn-ui;
        &.link-reorder {
          order: 2;
          @extend .btn-ui2;
        }
    }
    button {
      @extend .btn;
      @extend .btn-md;
      @extend .btn-ui2;     
    }
    .separator {
      display: none;
    }
  }
// }

.newsletter-manage-index {
  .buttons-set {
    align-items: start;
    flex-direction: column-reverse;
  }
}

.box-head {
  margin-bottom: 16px;
  a {
      @extend .btn;
      @extend .btn-sm;
      //@extend .btn-ui2;
      background-color: $color-brand;
			border-color: $color-brand;
      &.link-print {
        @extend .btn-ui2;
      }
  }
}
.title-buttons h1 {
  flex-basis: 100%;
}

.title-buttons {
  button,
  a {
    min-width: max-content;
  }
}

.header-language-background .welcome-msg,
.form-language select,
.form-language select option {
	font-size: 12px;
  @media (min-width: 887px) {
    // font-size: 14px;
  }
}
.form-language {
	display: inline-block;
	float: right;
	label {
		display: none;
	}
}
.validation-advice,
.note {
	font-size: $font-xs;
	color: $color-muted;
}
// .category-description {
// 	padding: .5em;
// }
.validation-advice {
	color: $color-danger;
}
.no-display {
	display: none;
}
.a-left {
  text-align: left;
}
.a-right {
  text-align: right;
}
.a-center {
  text-align: center;
}
label.required em, span.required em {
  color: #df280a;
  margin-right: 4px;
}
body:not(.catalog-product-view) {
  label.required em, span.required em {
    display: none;
  }
  label.required::after, span.required::after {
    content: ' *';
    color: #df280a;
    font-weight: normal;
    // font-family: "Helvetica Neue", Verdana, Arial, sans-serif;
    font-size: 14px;
  }
}
input[type="file"] ~ p {
  @extend .font-xs;
  @extend .text-muted;
}
.product-options {
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.02);
  background: #fbfbfb;
  border: 1px solid #f7f7f7;
  padding: 16px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  dl {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    dt:not(:first-of-type) {
      padding-top: 12px;
    }
    @media (min-width: 778px) {   
      /* display: grid;
      grid-template-columns: minmax(30%, max-content) 1fr; */
      gap: 16px;
    }
  }
  dd {
    /* &:not(:last-child) {
      margin-bottom: 16px;
    } */
    display: flex;
    gap: 4px;
    & > * {
      flex: 1;
    }
    textarea {
      width: 100%;
    }
  }
}
.product-options p.required {
  color: #df280a;

}
p.required, .validation-advice {
  margin: 4px 0 0;
  color: #df280a;
  font-size: $font-xs;
}
.item-options {
  font-size: $font-sm;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-column-gap: 8px;
  // margin: 8px 0;
  // max-width: 140px;
  dt {
    // font-weight: 600;
    color: $color-text;
    float: left;
    clear: both;
    margin-right: 6px;
    line-height: initial;
  }
  dd {
    color: $color-brand;
    float: left;
    line-height: initial;
  }
}
// dl {
//   display: flex;
//   flex-wrap: wrap;
//   width: 100%;
// }
// dt {
//   font-weight: bold;  
//   width: 25%;
//   padding-top: 1em;  
// }
// dd {
//   display: flex;
//   margin: 0;
//   padding-top: 1em;
// }

// dd + dd {
//   width: 100%; 
//   padding-top: 0;  
// }
// dt + dd:not(:nth-child(2)),
// dt:first-child + dd:nth-child(2) {
//   width: 75%;
// }
// dd + dd::before {
//   width: 25%;
//   content: "";
//   padding-top: 0
// }
dt label {
  font-weight: 500;
  color: $color-body;
  font-size: $font-md;
  text-transform: uppercase;
  .select-label {
    font-size: $font-xs;
    text-transform: none;
  }
}


.tier-prices {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8px auto;
  background: #fbfbfb;
  padding: 16px;
  border-radius: 8px; */

  margin: 8px auto;
  background: #fbfbfb;
  padding: 16px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;
  font-size: 12px;
  & > * {
    flex: 0;
    display: flex;
    flex-direction: column;
    max-width: min-content;
    text-align: center;
    background: #fff;
    //padding: 8px;
    border: 1px solid #e53e59;
    border-radius: 8px;
    //cursor: pointer;
  }
}

.product-pricing .messages li {
  font-size: $font-xs;
  font-weight: normal;
}

.tier-price {

  .price,
  .benefit {
    font-weight: 600;
  }
  .price {
    font-size: $font-lg;
  }
  .benefit {
    font-size: 14px;
    background: #e53e59;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
  }

}

.availability {
  // text-align: center;
  font-size: .95em;
}
.availability.in-stock {
  color: green;
}
.availability.out-of-stock {
  color: $color-danger;
}
.availability.out-of-stock.preorder {
  color: orange;
}

// .category-products .availability {
//   position: absolute;
//   font-size: 13px;
//   bottom: 2.8em;

//   @media only screen and (min-width:576px){
//     position: relative;
//     padding: .85em 1em;
//     font-size: initial;
//     bottom: inherit;
//   }

// }
.category-image {
  text-align: center;
  img {
    /* border-radius: 8px; */
    max-width: 100%;
    height: auto;
  }
}
// reset product description styles
body:not(.cms-index-index) 
.product-view
.std {
  // margin-top:10px;
  // font-size: inherit !important;
  font-family: $font-heading !important;
  h1 {
    // font-size: inherit !important;
  }
  span,
  strong,
  font {
    // font-size: inherit !important;
    font-family: $font-heading !important;
  }
  ul:not(.products-grid) {
    list-style: disc;
    margin: 10px 20px;
    li {
      @extend .font-sm;
      line-height: 1.6;
      padding: 2px 0;
    }
  }
}
.product-collateral {
  text-align: left;
  .std {
    /* max-width: 800px; */
    p {
      line-height: 1.8;
      margin: 8px 0;
    }
    /* margin: 0 auto; */
  }
}


.box-collateral {

  margin-bottom: 16px;
  table {
    margin-top: 8px;
    width: 100% !important;
    max-width: 600px;
    tr,
    tbody {
      background-color: #fff;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    tr td:first-child,
    tr th:first-child {
      font-weight: 700;
      color: $color-text;
      // color: $color-brand;
    }
    tr td {
      // color: $color-muted;
      text-align: left;
    }
  }
}

.product-attributes,
.product-collateral {
  table {
      display: block;
      overflow: auto;
      width: auto !important;
      @media (min-width:576px){
        display: table;
        width: 100% !important;
        max-width: 600px;
      }
      display: block;
      height: auto !important;
      margin: 0 !important;
      tr,
      tbody {
        background-color: #fff;
      }
      tr:nth-child(even) {
        background-color: #f2f2f2;
      }
      p {
        font-weight: inherit !important;
        font-size: inherit !important;
        color: inherit !important;
      }
      tbody {
        display: inline-block;
        width: fit-content !important;
        border: 1px solid #eee;
        border-radius: 4px;
        padding: 16px;
        tr {
          width: fit-content !important;
        }
      }
      tr:first-child td {
        font-weight: 700;
        font-weight: bold;
      }
      tr td {
        padding: 8px 16px;
        color: $color-text;
        width: fit-content !important;
        border: 0;
        border-bottom: 1px solid #928f8f;
        text-align: left;
        span,
        strong,
        font {
          font-size: inherit !important;
        }
      }
    }
  }
  .product-attributes .data {
    p,
    span,
    strong {
      //font-weight: inherit !important;
      font-size: inherit !important;
      //color: inherit !important;
    }
}

.remember-me-popup {
  margin: 8px auto;
  padding: 8px 16px;
  border: 1px solid #eee;
  display: none;
  &.show {
    display: block;
  }
  .remember-me-popup-close-button {
    display: none;
  }
  .remember-me-popup-head {
    display: flex;
    justify-content: space-between;
  }
}
.remember-me-box {
  .link-tip.hide {
    display: none;
  }
}




.data-table {
  display: block;
  overflow: auto;
  @media (min-width:576px){
    display: table;
  }
  /* tr,
  tbody {
    background-color: #fff;
  } */
}
table {
  width: 100%;
  margin-bottom: 1.5rem;
  border-collapse: collapse;
  vertical-align: middle;

  td,
  th {
    text-align: left;
    padding: 16px 16px;
    //border: 1px solid $border-color;
    vertical-align: middle;
  }
 

  thead>tr>th {
    background: $color-grey-1;
    color: white; 
    vertical-align: middle;
    border-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  &.clean {

    td,
    th {
      border: 0;
    }
  }

  .product-thumbnail img {
    max-width: 80px;
  }

  /* reset */
  h1,
  h2,
  h3 {
    font-size: inherit !important;
  }
}

.checkoutsuccess-block-wrapper {
  .box-payment {
    .box-content {
      display: flex;
      flex-direction: column;
      table td {
        padding: 0;
      }
    }
  }
}

.review-heading {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0;
  p {
    font-size: 14px;
    line-height: 16px;
  }
}
.review-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  @media (min-width: 778px) {
    flex-direction: row;    
    & > * {
      flex-basis: 45%;
    }
  }
  gap: 16px;
  flex-wrap: wrap;
}
.product-review {
  display: grid;
  grid-template-areas:
    'title   title  ' 
    'img     review ' 
    'buttons buttons';
  .page-title {
    grid-area: title;
  }
  .product-img-box {
    grid-area: img;
  }
  .review-item {
    grid-area: review;
  }
  .buttons-set {
    grid-area: buttons;
  }
}
.review-item {


  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.02);
  padding: 16px;
  
  .ratings-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: min-content;
    gap: 4px;
    .label {
      /* display: none; */
      font-weight: bold;
    }
    margin-bottom: 16px;
  }
  .review-title {
    font-weight: bold;
  }
  .review-meta {
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;  
      .review-author {
        font-size: 20px;
        font-weight: 700;
      }
    }

    margin-bottom: 16px;

  }
  .review-date {
    font-size: 12px;
    color: $color-muted;
  }
  .review-body {
    font-size: $font-sm;
  }
}
.box-reviews .form-add {
  /* max-width: fit-content;
  margin: 0 auto; */
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.02);
  padding: 8px 16px;
  &:has(> .review-nologged) {
    max-width: fit-content;
  }
  
  .review-nologged a {
    text-decoration: underline;
  }
  h3 > span {
    color: $color-brand;
  }
  .review-summary-table {
    display: flex;

    max-width: 100%;
    th,
    td.value {
      text-align: center;
    }
  }
  .fieldset {
    margin: 16px 0;
  }
  .rating-box {
    display: inline-flex;
  }
  
  textarea {
    width: 100%;
    min-height: 150px;
  }
}



.product-view .product-essential .product-img-box .zoomview {
  display: block;
  width: 100%;
}
.product-view .product-essential .product-img-box.zoomOpen {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  max-width: inherit;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  /* background-color: rgba(0,0,0,.85);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px); */
  background-color: white;
  .product-badges {
    display: none;
  }
  .product-image {
    flex: 1;
    border: none;
    border-radius: none;
    overflow: auto;
    width: auto;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-image-gallery {
    display: flex;
    max-height: 100%;
  }
  img {
    aspect-ratio: inherit;
    max-width: 100%;
    width: auto;
    max-height: 100%;
  }

  
  @media (min-width: 778px) {  
    .product-image-thumbs {
      display: flex;
      img {
        max-width: 75px;
      }
    }
  }
}

.panel-group {
  border-radius: 8px 8px 0 0;
  background: #f5f5f5;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 20px;
}
.panel-heading {
  .panel-title {
    display: flex;
    justify-content: space-between;
  }
  :after {
    content: url('data:image/svg+xml,\
		<svg viewBox="0 0 11 10" width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">\
	    <path d="M1.97275 3.71249L4.68942 6.42916C5.01025 6.74999 5.53525 6.74999 5.85609 6.42916L8.57275 3.71249" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\
	</svg>');
    display: inline-block;
  }

  border: 1px solid #eee;
  background: #fff;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  a {
    text-decoration: none !important;
  }
  &.active a {
    color: $color-brand;
  }
}
.panel-body {
  display: none;
  background: #fff;
  border: 1px solid #eee;
  padding: 8px 16px;
  &.active {
    display: block;
  }
}
.whatsapp_widget {
  position: fixed; 
  bottom: 20px;
  right: 20px;
  z-index: 999;
  a {
    display: flex;
    position: relative;
    align-items: center;
    gap: 4px;
    @media (min-width: 768px) {
      padding: 6px;
      border-radius: 6px;
      box-shadow: 6px 4px 10px rgba(0,0,0,.3);
      background: #eee;
      color: black;
    }
    span {
      display: none;
      @media (min-width: 768px) {
        display: inherit;
      }
    }
    svg {
      width: 40px;
      height: 40px;
      @media (min-width: 768px) {
        width: 24px;
        height: 24px;
      }
      filter: drop-shadow(6px 4px 10px rgba(0,0,0,.3));
      @media (min-width: 768px) {
        filter: none;
      }
      .st0{fill:#25D366;}
      .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
    }
  }
}
@media (max-width: 768px) {
  .catalog-product-view .whatsapp_widget {
    display: none !important;
  }
}

#customer-recent-reviews {
  margin-top: 0;
  .review-heading {
    gap: unset;
    max-width: var(--max-width-container);
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .block-content {
    max-width: none;
  }
  #customer-recent-reviews-items.products-grid {
    --grid-column-count: 5;
    --grid-item--max-width: 260px;
    --grid-item--min-width: 260px;
    margin: 0 40px;
    .item.review-item {
      main {
        flex: 1;
      }
      //justify-content: unset;
      .review-meta {
        margin-bottom: 4px;
      }
    }
    .product-name {
      /* font-weight: 600; */
      line-height: normal;
    }
    .review-title {
      font-size: 16px;
      margin-bottom: 4px;
    }
  }
}

.payment-list li {
  line-height: 0;
}
/*! purgecss end ignore */